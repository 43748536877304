<template>
    <AdminLayout>
         <!-- Vertical Wizard -->
<div class="col-12 mb-4">
    <strong><small class="text-light_ fw-semibold">Add Enrollment</small></strong>
    <div class="bs-stepper wizard-vertical vertical mt-2">
         <wizardHeader></wizardHeader>

        <div id="formAddEnrollment" class="bs-stepper-content jsvalidate">
            <div>
                <tab1 />
                <tab2 />
                <tab3 />
                <tab4 />
                <tab5 />
                <tab6 />
                <tab7 />
            </div>
            <modalAddress></modalAddress>
        </div>
    </div>

</div>
<!-- /Vertical Wizard -->

    </AdminLayout>
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted } from "vue"; 
  import AdminLayout from "@/components/layouts/AdminLayout.vue";
  import wizardHeader from "@/views/enrollment/partials/wizardHeader.vue";
  import tab1 from "@/views/enrollment/partials/tab1.vue";
  import tab2 from "@/views/enrollment/partials/tab2.vue";
  import tab3 from "@/views/enrollment/partials/tab3.vue";
  import tab4 from "@/views/enrollment/partials/tab4.vue";
  import tab5 from "@/views/enrollment/partials/tab5.vue";
  import tab6 from "@/views/enrollment/partials/tab6.vue";
  import tab7 from "@/views/enrollment/partials/tab7.vue";
  import modalAddress from "@/views/enrollment/partials/modalAddress.vue";
  //
  import { EnrollmentStore } from "./EnrollmentStore";

  const appJs = require('@/assets/app-service.js');
 
  export default defineComponent({
    name: "AddEnrollmentPage",
    components: { 
      AdminLayout,  
      wizardHeader,
      tab1, tab2, tab3, tab4, tab5, tab6, tab7,
      modalAddress
    },
    beforeMount() {
      document.title = "Add Enrollment";
    },
    mounted() { 
        
    },
    setup() { 
      const service = EnrollmentStore();

      onMounted(() => { 
          //formValidator.PageLoading(false);
          appJs.fxnLoadPage(3000);
      });
      
      return {
        service,
        //appuser: Apphelper.getLoggedUser()
      };
    }
  });
  </script>
  