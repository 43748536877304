<template>
    <!-- <span>{{ msg }}</span> -->
    <!-- Employment Details -->
    <div id="wiz-enroll-content-3" class="content wiz-enroll-content">
                    <div class="content-header mb-3">
                        <h6 class="mb-0">Employment Information</h6>
                        <small>Enter the employment data.</small>
                    </div>
                    <div class="row g-3">
                        <hr />
                        <div class="">
                            <button onclick="showCanvas('#modal-add-employer');"
                                    type="button" class="btn btn-outline-primary btn-sm m-1">
                                <i class="fa fa-plus"></i> &nbsp; Add Current Employer
                            </button>
                        </div>
                        <div class="row mb-2">
                            <div class="mb-1 col-md-12">
                                <table class="table table-striped">
                                    <tbody>
                                        <tr>
                                            <th class="text-right" style="width:120px;">Employer</th>
                                            <td> </td>
                                            <th style="width:120px;" class="text-right">Code</th>
                                            <td>   </td>
                                        </tr>
                                        <tr>
                                            <th class="text-right">Type</th>
                                            <td>  </td>
                                            <th class="text-right">Sector</th>
                                            <td>  </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="row">
                            <div class="mb-1 col-md-6">
                                <label class="form-label">Designation</label>
                                <input type="text" class="form-control" jsvalidate="[type:text,]"
                                       placeholder="Enter the Designation" name="Designation"
                                       maxlength="150" />
                            </div>

                            <div class="mb-1 col-md-6">
                                <label class="form-label" for="ad_fknatureofbusiness">Nature Of Business</label>
                                <select id="ad_fknatureofbusiness" class="form-select select2" title="Select the Nature Of Business"
                                        name="FkNatureOfBusiness">
                                    <option value="">Select Nature Of Business</option>
                                </select>
                            </div>
                        </div>
                        <hr />

                        <div class="row">
                            <div class="mb-1 col-md-6">
                                <label class="form-label">Employee ID</label>
                                <input type="text" class="form-control" jsvalidate="[type:text,]"
                                       placeholder="Enter the Employee ID" name="Staffidno"
                                       maxlength="50" />
                            </div>

                            <div class="mb-1 col-md-6">
                                <label class="form-label">Service/IPPIS Number</label>
                                <input type="text" class="form-control" jsvalidate="[type:text,]"
                                       placeholder="Enter the Service/IPPIS Number" name="IppisNumber"
                                       maxlength="50" />
                            </div>

                        </div>
                        <div class="row">
                            <div class="mb-1 col-md-6">
                                <label class="form-label" for="ad_firstemploymentdate">First Employment Date</label>
                                <input type="text" name="FirstEmploymentDate" id="ad_firstemploymentdate"
                                       class="form-control jsdatepicker" readonly />
                            </div>

                            <div class="mb-1 col-md-6">
                                <label class="form-label" for="ad_ippisjoineddate">IPPIS Joined Date</label>
                                <input type="text" name="IppisJoinedDate" id="ad_ippisjoineddate"
                                       class="form-control jsdatepicker" readonly />
                            </div>
                        </div>
                        <div class="row">

                            <div class="mb-1 col-md-6">
                                <label class="form-label" for="ad_appointmentdate">Appointment Date</label>
                                <input type="text" name="AppointmentDate" id="ad_appointmentdate"
                                       class="form-control jsdatepicker" readonly />
                            </div>

                            <div class="mb-1 col-md-6">
                                <label class="form-label" for="ad_confirmationdate">Confirmation Date</label>
                                <input type="text" name="ConfirmationDate" id="ad_confirmationdate"
                                       class="form-control jsdatepicker" readonly />
                            </div>


                        </div>
                        <div class="row">
                            <div class="mb-1 col-md-6">
                                <label class="form-label">First Appointment</label>
                                <input type="text" class="form-control" jsvalidate="[type:text,]"
                                       placeholder="Enter the First Appointment" name="FirstAppointment"
                                       maxlength="0" />
                            </div>

                            <div class="mb-1 col-md-6">
                                <label class="form-label">Current Employment</label>
                                <input type="text" class="form-control" jsvalidate="[type:text,]"
                                       placeholder="Enter the Current Employment" name="CurrentEmployment"
                                       maxlength="0" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="mb-1 col-md-6">
                                <label class="form-label">Employee Contribution</label>
                                <input type="text" class="form-control" jsvalidate="[type:number,]"
                                       placeholder="Enter the Employee Contribution" name="EmployeeContribution"
                                       maxlength="50" />
                            </div>

                            <div class="mb-1 col-md-6">
                                <label class="form-label">Employer Contribution</label>
                                <input type="text" class="form-control" jsvalidate="[type:number,]"
                                       placeholder="Enter the Employer Contribution" name="Step"
                                       maxlength="50" />
                            </div>

                        </div>


                        <div class="col-12 d-flex justify-content-between">
                            <button onclick="gotoStep('wiz-enroll', 2)" class="btn btn-label-secondary btn-prev waves-effect">
                                <i class="ti ti-arrow-left me-sm-1 me-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                            </button>
                            <button onclick="gotoStep('wiz-enroll', 4)"
                                    class="btn btn-primary btn-next waves-effect waves-light">
                                <span class="align-middle d-sm-inline-block d-none me-sm-1">Next</span> <i class="ti ti-arrow-right"></i>
                            </button>
                        </div>
                    </div>

                    <modalEmployer />
                </div>
 </template>
 
 <script lang="ts"> 
   import { defineComponent, onMounted } from "vue";
  import { EnrollmentStore } from "./../EnrollmentStore";
  import modalEmployer from "@/views/enrollment/partials/modalEmployer.vue";
 
 export default defineComponent({
 name: "tab3",
 components: { 
    modalEmployer
 },
 //props: ['msg'],
 props:{
   msg:{
     type: String,
     default: ""
   }
 },
 beforeMount() {
   //localStorage.setItem("lpg-layout", "portal");
 },
 setup(props) {
    const service = EnrollmentStore();

   onMounted(() => {
     service.model.currentTab = 3;
     //Apphelper.checkAdminAuthenticated();
     //alert("hello admin layout");
     //alertStore.PageLoading(false); 
   });
 
 }
 
 });
 
 </script>
  