<template>
     <!-- <span>{{ msg }}</span> -->
     <div class="bs-stepper-header">
            <div class="step wiz-enroll active" id="wiz-enroll-1" onclick="gotoStep('wiz-enroll', 1)">
                <button type="button" class="step-trigger" aria-selected="true">
                    <span class="bs-stepper-circle">1</span>
                    <span class="bs-stepper-label">
                        <span class="bs-stepper-title">User Details</span>
                        <span class="bs-stepper-subtitle">Add User Account Details</span>
                    </span>
                </button>
            </div>

            <div class="line"></div>
            <div class="step wiz-enroll" id="wiz-enroll-2" onclick="gotoStep('wiz-enroll', 2)">
                <button type="button" class="step-trigger" aria-selected="false">
                    <span class="bs-stepper-circle">2</span>
                    <span class="bs-stepper-label">
                        <span class="bs-stepper-title">Personal Info</span>
                        <span class="bs-stepper-subtitle">Add personal information</span>
                    </span>
                </button>
            </div>

            <div class="line"></div>
            <div class="step wiz-enroll" id="wiz-enroll-3" onclick="gotoStep('wiz-enroll', 3)">
                <button type="button" class="step-trigger" aria-selected="false">
                    <span class="bs-stepper-circle">3</span>
                    <span class="bs-stepper-label">
                        <span class="bs-stepper-title">Employment Info</span>
                        <span class="bs-stepper-subtitle">Add employment information</span>
                    </span>
                </button>
            </div>

            <div class="line"></div>
            <div class="step wiz-enroll" id="wiz-enroll-4" onclick="gotoStep('wiz-enroll', 4)">
                <button type="button" class="step-trigger" aria-selected="false">
                    <span class="bs-stepper-circle">4</span>
                    <span class="bs-stepper-label">
                        <span class="bs-stepper-title">Next of Kin</span>
                        <span class="bs-stepper-subtitle">Add Next of Kin Information</span>
                    </span>
                </button>
            </div>

            <div class="line"></div>
            <div class="step wiz-enroll" id="wiz-enroll-5" onclick="gotoStep('wiz-enroll', 5)">
                <button type="button" class="step-trigger" aria-selected="false">
                    <span class="bs-stepper-circle">5</span>
                    <span class="bs-stepper-label">
                        <span class="bs-stepper-title">Pension Data</span>
                        <span class="bs-stepper-subtitle">Add Pension Scheme Information</span>
                    </span>
                </button>
            </div>

            <div class="line"></div>
            <div class="step wiz-enroll" id="wiz-enroll-6" onclick="gotoStep('wiz-enroll', 6)">
                <button type="button" class="step-trigger" aria-selected="false">
                    <span class="bs-stepper-circle">6</span>
                    <span class="bs-stepper-label">
                        <span class="bs-stepper-title">Passport &amp; Signature</span>
                        <span class="bs-stepper-subtitle">Add Images upload</span>
                    </span>
                </button>
            </div>

            <div class="line"></div>
            <div class="step wiz-enroll" id="wiz-enroll-7" onclick="gotoStep('wiz-enroll', 7)">
                <button type="button" class="step-trigger" aria-selected="false">
                    <span class="bs-stepper-circle">7</span>
                    <span class="bs-stepper-label">
                        <span class="bs-stepper-title">Preview</span>
                        <span class="bs-stepper-subtitle">Preview Information</span>
                    </span>
                </button>
            </div>
        </div>

  </template>
  
  <script lang="ts"> 
    import { defineComponent } from "vue";
  
  export default defineComponent({
  name: "wizardHeader",
  components: { 
  },   
  setup() { 
    
  }
  
  });
  
  </script>
   