<template>
      
<div id="modal-add-nok" class="offcanvas offcanvas-60 offcanvas-end jsvalidate">

<div class="offcanvas-header border-bottom">
    <h5 class="offcanvas-title">Add Next of Kin </h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
</div>

<div class="offcanvas-body flex-grow-1 px-2">
    <div class="col-md-12 pt-0">
        <div class="row">
            <div class="mb-1">
                <label class="form-label" for="ad_fkrelationshipnok">Relationship</label>
                <select id="ad_fkrelationshipnok" class="form-select select2"
                        title="Select the Relationship"
                        name="FkRelationshipNok">
                    <option value="">Select Relationship</option> 
                    <option v-for="(opt, indx) in service.listBasicDropdown.Relationship" :value="opt">{{ opt }}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="mb-1 col-md-6">
                <label class="form-label" for="ad_fktitlenok">Title</label>
                <select id="ad_fktitlenok" class="form-select select2"
                        title="Select the Title"
                        name="FkTitleNok">
                    <option value="">Select Title</option>
                    <option v-for="(opt, indx) in service.listBasicDropdown.Title" :value="opt">{{ opt }}</option>
                </select>
            </div>

            <div class="mb-1 col-md-6">
                <label class="form-label" for="ad_fkgendernok">Gender</label>
                <select id="ad_fkgendernok" class="form-select select2"
                        title="Select the Gender"
                        name="FkGenderNok">
                    <option value="">Select Gender</option>
                    <option v-for="(opt, indx) in service.listBasicDropdown.Gender" :value="opt">{{ opt }}</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="mb-1 col-md-6">
                <label class="form-label">Surname</label>
                <input type="text" class="form-control" jsvalidate="[type:text,]"
                       placeholder="Enter the Surname" name="NokSurname"
                       maxlength="50" />
            </div>

            <div class="mb-1 col-md-6">
                <label class="form-label">First Name</label>
                <input type="text" class="form-control" jsvalidate="[type:text,]"
                       placeholder="Enter the First Name" name="NokFirstName"
                       maxlength="50" />
            </div>
        </div>

        <div class="row">
            <div class="mb-1">
                <label class="form-label">Middle Name</label>
                <input type="text" class="form-control" jsvalidate="[type:text,]"
                       placeholder="Enter the Middle Name" name="NokMiddleName"
                       maxlength="50" />
            </div>

        </div>

        <div class="row">
            <div class="mb-1 col-md-6">
                <label class="form-label">Nok Email</label>
                <input type="email" class="form-control"
                       placeholder="Enter the Nok Email" name="NokEmail"
                       jsvalidate="[type:email,]"
                       maxlength="50" />
            </div>

            <div class="mb-1 col-md-6">
                <label class="form-label">Nok Phone</label>
                <input type="text" class="form-control" jsvalidate="[type:text,]"
                       placeholder="Enter the Nok Phone" name="NokPhone"
                       maxlength="50" />
            </div>
        </div>

        <hr />
        <div class="row">
            <div class="mb-1 col-md-6">
                <label class="form-label required" for="ad_fkcountry_nok">Country</label> 
                <select id="ad_fkcountry_nok" name="FkCountryNok" class="form-control select2"
                        onchange="fxnLoadCountryStates('#ad_fkcountry_nok', '#ad_fkstate_nok', '#ad_fklga_nok', '');">
                        <option value="">Select Country</option>
                        <option v-for="(opt,indx) in serviceCountry.listCountries" :value="opt.code3">{{ opt.name }}</option>
                </select> 
            </div>

            <div class="mb-1 col-md-6">
                <label class="form-label required" for="ad_fkstate_nok">State</label>
                <select id="ad_fkstate_nok" class="form-select" title="Select the State"
                        onchange="fxnLoadStateLGAs('#ad_fkstate_nok','#ad_fklga_nok', '');"
                        name="AdFkStateNok" required>
                    <option value="">Select State</option>
                </select>
                <!-- ad_fkcountry_addr, ad_fkstate_addr -->
            </div>
        </div>
       
        <div class="row">
            <div class="mb-1 col-md-6">
                <label class="form-label required" for="ad_fklga_nok">LGA</label>
                <select bind="formAddress.Lga" id="ad_fklga_nok" class="form-select"
                        title="Select the LGA"
                        name="AdFkLgaNok">
                    <option value="">Select LGA</option>
                </select>
            </div>

            <div class="mb-1 col-md-6">
                <label class="form-label">Village/Town</label>
                <input bind="formAddress.Town" type="text" class="form-control" jsvalidate="[type:text,minlength:3]"
                    placeholder="Enter the Village/Town" name="Town"
                    maxlength="50" />
            </div>
        </div>
       

        <div class="row">
            <div class="mb-1 col-md-6">
                <label class="form-label">Zip Code</label>
                <input bind="formAddress.ZipCode" type="text" class="form-control" jsvalidate="[type:text,]"
                       placeholder="Enter the Zip Code" name="ZipCode"
                       maxlength="50" />
            </div>

            <div class="mb-1 col-md-6">
                <label class="form-label">P.O Box</label>
                <input bind="formAddress.PoBox" type="text" class="form-control" jsvalidate="[type:text,]"
                       placeholder="Enter the P.O Box" name="PoBox"
                       maxlength="50" />
            </div>
        </div>


        <div class="mb-1">
            <label class="form-label required">Address</label>
            <input bind="formAddress.Address" type="text" class="form-control" jsvalidate="[type:text,req,minlength:5]"
                   placeholder="Enter the Address" name="Address"
                   maxlength="250" />
        </div>

        <div class="border-top mt-4 pt-3">

            <button type="button"  
                    class="btn btn-primary data-submit me-sm-3 me-1">
                Submit
            </button>

            <button type="button" onclick="JsValidate.Reset('#modal-add-nok');"
                    class="btn btn-outline-secondary me-sm-3 me-1" data-bs-dismiss="offcanvas">
                Cancel
            </button>

        </div>
    </div>

</div>

</div>
 </template>
 
 <script lang="ts"> 
   import { defineComponent, onMounted } from "vue";
  import { EnrollmentStore } from "./../EnrollmentStore";
  import { CountryStore } from "./../CountryStore";
 
 export default defineComponent({
 name: "modalNok",
 components: { 
 },
 //props: ['msg'],
 props:{
   msg:{
     type: String,
     default: ""
   }
 },
 beforeMount() {
   //localStorage.setItem("lpg-layout", "portal");
 },
 setup(props) { 
    const service = EnrollmentStore();
    const serviceCountry = CountryStore();

   onMounted(() => {
        serviceCountry.Init();

     //Apphelper.checkAdminAuthenticated();
     //alert("hello admin layout");
     //alertStore.PageLoading(false); 
   });
 

   return {
     service,
     serviceCountry
   }
 }
 
 });
 
 </script>
  