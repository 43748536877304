<template>
    <!-- <span>{{ msg }}</span> -->
    <div id="wiz-enroll-content-7" class="content wiz-enroll-content">
                    <div class="content-header mb-3">
                        <h6 class="mb-0">Preview Information</h6>
                        <small>Preview the form information.</small>
                    </div>
                    <div class="row g-3">
                        <h3>This is Preview of the documents</h3>
                        <div class="col-12 d-flex justify-content-between">
                            <button onclick="gotoStep('wiz-enroll', 6)" class="btn btn-label-secondary btn-prev waves-effect">
                                <i class="ti ti-arrow-left me-sm-1 me-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                            </button>
                            <button class="btn btn-success btn-submit waves-effect waves-light">Submit</button>


                           <!-- 
                            <button type="button" onclick="EnrollmentService.createRecord();"
                              class="btn btn-primary data-submit me-sm-3 me-1">
                              Submit
                              </button>

                              <button type="button" onclick="JsValidate.Reset('#div-addEnrollment');"
                                    class="btn btn-outline-secondary me-sm-3 me-1" data-bs-dismiss="offcanvas">
                                    Cancel
                              </button> 
                            -->
                        </div>
                    </div>
                </div>
 </template>
 
 <script lang="ts"> 
   import { defineComponent, onMounted } from "vue";
  import { EnrollmentStore } from "./../EnrollmentStore";
 
 export default defineComponent({
 name: "tab7",
 components: { 
 },
 //props: ['msg'],
 props:{
   msg:{
     type: String,
     default: ""
   }
 },
 beforeMount() {
   //localStorage.setItem("lpg-layout", "portal");
 },
 setup(props) {
      const service = EnrollmentStore();

   onMounted(() => {
        service.model.currentTab = 7;
     //Apphelper.checkAdminAuthenticated();
     //alert("hello admin layout");
     //alertStore.PageLoading(false); 
   });
 
 }
 
 });
 
 </script>
  