<template>
    <!-- <span>{{ msg }}</span> -->
    <div id="wiz-enroll-content-6" class="content wiz-enroll-content">
                    <div class="content-header mb-3">
                        <h6 class="mb-0">Passport &amp; Signature</h6>
                        <small>upload documents</small>
                    </div>
                    <div class="row g-3">


                        <div class="mb-1 mt-2">
                            <label class="form-label required">Passport</label>
                            <input type="file" id="ad_fkfilephoto" name="iFilePhoto" class="jsuploader" required />
                        </div>

                        <div class="mb-1 mt-2">
                            <label class="form-label required">Signature</label>
                            <input type="file" id="ad_fkfileSignature" name="iFileSignature" class="jsuploader" required />
                        </div>

                        <div class="mb-1 mt-2">
                            <label class="form-label required">Consent</label>
                            <input type="file" id="ad_fkfileConsent" name="iFileConsent" class="jsuploader" required />
                        </div>


                        <div class="col-12 d-flex justify-content-between">
                            <button onclick="gotoStep('wiz-enroll', 5)" class="btn btn-label-secondary btn-prev waves-effect">
                                <i class="ti ti-arrow-left me-sm-1 me-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                            </button>
                            <button onclick="gotoStep('wiz-enroll', 7)"
                                    class="btn btn-primary btn-next waves-effect waves-light">
                                <span class="align-middle d-sm-inline-block d-none me-sm-1">Next</span> <i class="ti ti-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
 </template>
 
 <script lang="ts"> 
   import { defineComponent, onMounted } from "vue";
  import { EnrollmentStore } from "./../EnrollmentStore";
 
 export default defineComponent({
 name: "tab6",
 components: { 
 },
 //props: ['msg'],
 props:{
   msg:{
     type: String,
     default: ""
   }
 },
 beforeMount() {
   //localStorage.setItem("lpg-layout", "portal");
 },
 setup(props) {
      const service = EnrollmentStore();

   onMounted(() => {
        service.model.currentTab = 6;
     //Apphelper.checkAdminAuthenticated();
     //alert("hello admin layout");
     //alertStore.PageLoading(false); 
   });
 
 }
 
 });
 
 </script>
  