<template>
  <AdminLayout>
    <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <HelloWorld msg="Welcome to Your Vue3.js & TypeScript App" />
  </div> 
  </AdminLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import AdminLayout from "@/components/layouts/AdminLayout.vue";

export default defineComponent({
  name: 'HomeView',
  components: {
    HelloWorld,
    AdminLayout
  },
  
  mounted() { 

  },
});
</script>
