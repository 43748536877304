<template>
  <!-- Footer -->
  <footer class="content-footer footer bg-footer-theme">
    <div class="container-xxl">
      <div class="footer-container d-flex align-items-center justify-content-between py-2 flex-md-row flex-column">
        <div>
          © 2024, made with ❤️ by <a href="#" class="fw-medium">Alpha Technologies</a>
        </div>
        <div class="d-none d-lg-inline-block">
          <a href="#" class="footer-link me-4">License</a>
          <a href="#" class="footer-link me-4">More Themes</a>
          <a href="#" class="footer-link me-4">Documentation</a>
          <a href="#" class="footer-link d-none d-sm-inline-block">Support</a>
        </div>
      </div>
    </div>
  </footer>
  <!-- / Footer -->
</template>


<script lang="ts">
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "adminFooter",
  components: {
    
  },
  beforeMount() {
    //localStorage.setItem("lpg-layout", "portal");
  },
  setup() {

    onMounted(() => {
      //Apphelper.checkAdminAuthenticated();
      //alert("hello admin layout");
      //alertStore.PageLoading(false);

      console.log('This is admin layout');
    });

  }

});

</script>
