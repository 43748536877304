<template>
    
<div id="modal-add-employer" class="offcanvas offcanvas-60 offcanvas-end jsvalidate">

<div class="offcanvas-header border-bottom">
    <h5 class="offcanvas-title">Add Employer </h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
</div>

<div class="offcanvas-body flex-grow-1 px-2">
    <div class="col-md-12 pt-0">

        <div class="mb-1 col-md-6">
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Search employer">
                <span class="input-group-text cursor">
                    <i class="fa fa-search"></i>
                </span>
            </div>
        </div>
        <hr />
        <div class="mb-1">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Code</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> Agip oil Company </td>
                        <td> Private </td>
                        <td> EAG001-2098</td>
                        <td>
                            <button type="button" class="btn btn-sm btn-success" data-bs-dismiss="offcanvas">
                                <i class="fa fa-plus"></i>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td> Elliot Enterprise Solutions </td>
                        <td> Private </td>
                        <td> EAG001-12938</td>
                        <td>
                            <button type="button" class="btn btn-sm btn-success" data-bs-dismiss="offcanvas">
                                <i class="fa fa-plus"></i>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td> Zenith Public Solutions </td>
                        <td> Public </td>
                        <td> EAG001-12938</td>
                        <td>
                            <button type="button" class="btn btn-sm btn-success" data-bs-dismiss="offcanvas">
                                <i class="fa fa-plus"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

       <!-- <div class="border-top mt-4 pt-3">

    <button type="button" 
    class="btn btn-primary data-submit me-sm-3 me-1">
    Submit
    </button>

    <button type="button" onclick="JsValidate.Reset('#modal-add-address');"
    class="btn btn-outline-secondary me-sm-3 me-1" data-bs-dismiss="offcanvas">
    Cancel
    </button>

    </div>-->
    </div>

</div>

</div>
 </template>
 
 <script lang="ts"> 
   import { defineComponent, onMounted } from "vue";
 
 export default defineComponent({
 name: "modalEmployer",
 components: { 
 },
 //props: ['msg'],
 props:{
   msg:{
     type: String,
     default: ""
   }
 },
 beforeMount() {
   //localStorage.setItem("lpg-layout", "portal");
 },
 setup(props) {
   console.log("Props:", props);
   onMounted(() => {
     //Apphelper.checkAdminAuthenticated();
     //alert("hello admin layout");
     //alertStore.PageLoading(false); 
   });
 
 }
 
 });
 
 </script>
  