<template>  
   <div class="layout-container">
        <!-- Menu -->
        <adminAside></adminAside>
        <!-- / Menu -->
        <!-- Layout container -->
        <div class="layout-page">

            <adminNav></adminNav>
            
            <!-- Content wrapper -->
            <div class="content-wrapper">

                <div class="px-4 flex-grow-1 container-p-y">

                    <!-- <flash dismissable="true" /> -->
                    <form :id="'frmpg_' + guidId" method="post">
                        <input type="hidden" :id="'hidpg_' + guidId" />
                    </form>

                    <slot></slot>
                </div>

                <adminFooter></adminFooter>

                <div class="content-backdrop fade"></div>
            </div>
            <!-- Content wrapper -->
        </div>
        <!-- / Layout page -->
    </div>

    <!-- Overlay -->
    <div class="layout-overlay layout-menu-toggle"></div>

    <!-- Drag Target Area To SlideIn Menu On Small Screens -->
    <div class="drag-target"></div>
  
</template>
  
  <script lang="ts">
    import { defineComponent, onMounted, ref } from "vue";
    import adminAside from "@/components/layouts/partials/adminAside.vue";
    import adminNav from "@/components/layouts/partials/adminNav.vue";
    import adminFooter from "@/components/layouts/partials/adminFooter.vue";
  // import { Apphelper } from '@/helpers/AppAlert';
     const appJs = require('@/assets/app-service.js');
 
  
  export default defineComponent({
    name: "AdminLayout",
    components: {
      adminNav,
      adminAside,
      adminFooter
    },
    beforeMount() {
      //localStorage.setItem("lpg-layout", "portal");
    },
    setup() {
    const guidId = ref<string>("");
  
      onMounted(() => { 
        guidId.value = appJs.GlobalService.GetGuid();
 
        //alert("GUID:" + guid);
        //Apphelper.checkAdminAuthenticated();
        //alert("hello admin layout");
        //alertStore.PageLoading(false); 
      });
  
      return {
        guidId
      };
    }
  
  });
  
  </script>
  