<template>
    <!-- <span>{{ msg }}</span> -->
    <div id="modal-add-address" class="offcanvas offcanvas-60 offcanvas-end jsvalidate">

<div class="offcanvas-header border-bottom">
    <h5 class="offcanvas-title">Add Address </h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
</div>

<div class="offcanvas-body flex-grow-1 px-2">
    <div class="col-md-12 pt-0">

        <div class="mb-1">
            <label class="form-label required" for="ad_fkcountry">Country</label>
             <select id="ad_fkcountry_addr" name="FkCountryAddr" class="form-control select2"
                        onchange="fxnLoadCountryStates('#ad_fkcountry_addr', '#ad_fkstate_addr', '#ad_fklga_addr', '');">
                    <option value="">Select Country</option>
                    <option v-for="(opt,indx) in serviceCountry.listCountries" :value="opt.code3">{{ opt.name }}</option>
                </select>
            <!-- 
                <select id="ad_fkcountry_addr" name="FkCountryAddr" class="form-control select2"
                        onchange="fxnLoadCountryStates('#ad_fkcountry_addr', '#ad_fkstate_addr', '#ad_fklga_addr', '');">
                    <option value="">Select Country</option>
                </select>
            -->
        </div>

        <div class="mb-1">
            <label class="form-label required" for="ad_fkstate_addr">State</label>
            <select id="ad_fkstate_addr" class="form-select" title="Select the State"
                    onchange="fxnLoadStateLGAs('#ad_fkstate_addr','#ad_fklga_addr', '');"
                    name="AdFkStateAddr" required>
                <option value="">Select State</option>
            </select>
            <!-- ad_fkcountry_addr, ad_fkstate_addr -->
        </div>

        <div class="mb-1">
            <label class="form-label required" for="ad_fklga_addr">LGA</label>
            <select id="ad_fklga_addr" class="form-select" title="Select the LGA"
                    name="AdFkLgaAddr" required>
                <option value="">Select LGA</option>
            </select>
        </div>

        <div class="mb-1">
            <label class="form-label">Village/Town</label>
            <input type="text" class="form-control" jsvalidate="[type:text,minlength:3]"
                   placeholder="Enter the Village/Town" name="Town"
                   maxlength="50" />
        </div>

        <div class="row">
            <div class="mb-1 col-md-6">
                <label class="form-label">Zip Code</label>
                <input type="text" class="form-control" jsvalidate="[type:text,]"
                       placeholder="Enter the Zip Code" name="ZipCode"
                       maxlength="50" />
            </div>

            <div class="mb-1 col-md-6">
                <label class="form-label">P.O Box</label>
                <input type="text" class="form-control" jsvalidate="[type:text,]"
                       placeholder="Enter the P.O Box" name="PoBox"
                       maxlength="50" />
            </div>
        </div>


        <div class="mb-1">
            <label class="form-label required">Address</label>
            <input type="text" class="form-control" jsvalidate="[type:text,req,minlength:5]"
                   placeholder="Enter the Address" name="Address"
                   maxlength="250" />
        </div>

        <div class="border-top mt-4 pt-3">

            <button type="button" 
                    class="btn btn-primary data-submit me-sm-3 me-1">
                Submit
            </button>

            <button type="button" onclick="JsValidate.Reset('#modal-add-address');"
                    class="btn btn-outline-secondary me-sm-3 me-1" data-bs-dismiss="offcanvas">
                Cancel
            </button>

        </div>
    </div>

</div>

</div>
 </template>
 
 <script lang="ts"> 
   import { defineComponent, onMounted } from "vue";
  import { CountryStore } from "./../CountryStore";
 
 export default defineComponent({
 name: "modalAddress",
 components: { 
 },
 //props: ['msg'],
 props:{
   title:{
     type: String,
     default: ""
   }
 },
 beforeMount() {
   //localStorage.setItem("lpg-layout", "portal");
 },
 setup(props) {
      const serviceCountry = CountryStore();
  
      console.log("Props:", props);
   onMounted(() => {
        serviceCountry.Init();

     //Apphelper.checkAdminAuthenticated();
     //alert("hello admin layout");
     //alertStore.PageLoading(false); 
   });
 
   return {
      serviceCountry
   }
 }
 
 });
 
 </script>
  