<template>
   <!-- Menu -->
   <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">


<div class="app-brand demo ">
    <a href="/" class="app-brand-link">
        <span class="app-brand-logo demo">
            <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z" fill="#7367F0" />
                <path opacity="0.06" fill-rule="evenodd" clip-rule="evenodd" d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z" fill="#161616" />
                <path opacity="0.06" fill-rule="evenodd" clip-rule="evenodd" d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z" fill="#161616" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z" fill="#7367F0" />
            </svg>
        </span>
        <span class="app-brand-text demo menu-text fw-bold">Alpha</span>
    </a>

    <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto">
        <i class="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle"></i>
        <i class="ti ti-x d-block d-xl-none ti-sm align-middle"></i>
    </a>
</div>

<div class="menu-inner-shadow"></div>



<ul class="menu-inner py-1">
    <!-- Dashboards -->
    <li class="menu-item" style="display:none;">
        <a href="javascript:void(0);" class="menu-link menu-toggle">
            <i class="menu-icon tf-icons ti ti-smart-home"></i>
            <div data-i18n="Dashboards">Dashboards</div>
            <div class="badge bg-primary rounded-pill ms-auto">3</div>
        </a>
        <ul class="menu-sub">
            <li class="menu-item">
                <a href="index.html" class="menu-link">
                    <div data-i18n="Analytics">Analytics</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="dashboards-crm.html" class="menu-link">
                    <div data-i18n="CRM">CRM</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="app-ecommerce-dashboard.html" class="menu-link">
                    <div data-i18n="eCommerce">eCommerce</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="app-logistics-dashboard.html" class="menu-link">
                    <div data-i18n="Logistics">Logistics</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="app-academy-dashboard.html" class="menu-link">
                    <div data-i18n="Academy">Academy</div>
                </a>
            </li>
        </ul>
    </li>

    <!-- Layouts -->
    <li class="menu-item" style="display:none;">
        <a href="javascript:void(0);" class="menu-link menu-toggle">
            <i class="menu-icon tf-icons ti ti-layout-sidebar"></i>
            <div data-i18n="Layouts">Layouts</div>
        </a>

        <ul class="menu-sub">

            <li class="menu-item">
                <a href="layouts-collapsed-menu.html" class="menu-link">
                    <div data-i18n="Collapsed menu">Collapsed menu</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="layouts-content-navbar.html" class="menu-link">
                    <div data-i18n="Content navbar">Content navbar</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="layouts-content-navbar-with-sidebar.html" class="menu-link">
                    <div data-i18n="Content nav + Sidebar">Content nav + Sidebar</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="https://demos.pixinvent.com/vuexy-html-admin-template/html/horizontal-menu-template" class="menu-link" target="_blank">
                    <div data-i18n="Horizontal">Horizontal</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="layouts-without-menu.html" class="menu-link">
                    <div data-i18n="Without menu">Without menu</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="layouts-without-navbar.html" class="menu-link">
                    <div data-i18n="Without navbar">Without navbar</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="layouts-fluid.html" class="menu-link">
                    <div data-i18n="Fluid">Fluid</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="layouts-container.html" class="menu-link">
                    <div data-i18n="Container">Container</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="layouts-blank.html" class="menu-link">
                    <div data-i18n="Blank">Blank</div>
                </a>
            </li>
        </ul>
    </li>

    <!-- Enrollment -->
    <li class="menu-item">
        <a href="/add-enrollment" class="menu-link menu-toggle">
            <i class='menu-icon tf-icons ti ti-files'></i>
            <div data-i18n="Enrollment">Enrollment </div>
        </a>
        <ul class="menu-sub">
            <li class="menu-item">
                <a href="/add-enrollment" class="menu-link">
                    <div data-i18n="Add Enrollment">Add Enrollment</div>
                </a>
            </li>
             
        </ul>
    </li>


    <!-- Apps & Pages -->
  <li class="menu-header small text-uppercase">
        <span class="menu-header-text">Home</span>
    </li>
    <li class="menu-item">
        <a href="/" class="menu-link">
            <i class="menu-icon tf-icons ti ti-mail"></i>
            <div data-i18n="Home">Home</div>
        </a>
    </li>
    <li class="menu-item">
        <a href="/counter" class="menu-link">
            <i class="menu-icon tf-icons ti ti-messages"></i>
            <div data-i18n="Counter">Counter</div>
        </a>
    </li>
    <li class="menu-item">
        <a href="/fetchdata" class="menu-link">
            <i class="menu-icon tf-icons ti ti-calendar"></i>
            <div data-i18n="Fetch Data">Fetch Data</div>
        </a>
    </li>
    <li class="menu-item">
        <a href="/weather" class="menu-link">
            <i class="menu-icon tf-icons ti ti-layout-kanban"></i>
            <div data-i18n="Weather">Weather</div>
        </a>
    </li>   
    <!-- e-commerce-app menu start -->
 
    <!-- Extended components -->
    <li class="menu-item" style="display:none;">
        <a href="javascript:void(0)" class="menu-link menu-toggle">
            <i class="menu-icon tf-icons ti ti-components"></i>
            <div data-i18n="Extended UI">Extended UI</div>
        </a>
        <ul class="menu-sub">
            <li class="menu-item">
                <a href="extended-ui-avatar.html" class="menu-link">
                    <div data-i18n="Avatar">Avatar</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="extended-ui-blockui.html" class="menu-link">
                    <div data-i18n="BlockUI">BlockUI</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="extended-ui-drag-and-drop.html" class="menu-link">
                    <div data-i18n="Drag & Drop">Drag &amp; Drop</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="extended-ui-media-player.html" class="menu-link">
                    <div data-i18n="Media Player">Media Player</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="extended-ui-perfect-scrollbar.html" class="menu-link">
                    <div data-i18n="Perfect Scrollbar">Perfect scrollbar</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="extended-ui-star-ratings.html" class="menu-link">
                    <div data-i18n="Star Ratings">Star Ratings</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="extended-ui-sweetalert2.html" class="menu-link">
                    <div data-i18n="SweetAlert2">SweetAlert2</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="extended-ui-text-divider.html" class="menu-link">
                    <div data-i18n="Text Divider">Text Divider</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="javascript:void(0);" class="menu-link menu-toggle">
                    <div data-i18n="Timeline">Timeline</div>
                </a>
                <ul class="menu-sub">
                    <li class="menu-item">
                        <a href="extended-ui-timeline-basic.html" class="menu-link">
                            <div data-i18n="Basic">Basic</div>
                        </a>
                    </li>
                    <li class="menu-item">
                        <a href="extended-ui-timeline-fullscreen.html" class="menu-link">
                            <div data-i18n="Fullscreen">Fullscreen</div>
                        </a>
                    </li>
                </ul>
            </li>
            <li class="menu-item">
                <a href="extended-ui-tour.html" class="menu-link">
                    <div data-i18n="Tour">Tour</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="extended-ui-treeview.html" class="menu-link">
                    <div data-i18n="Treeview">Treeview</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="extended-ui-misc.html" class="menu-link">
                    <div data-i18n="Miscellaneous">Miscellaneous</div>
                </a>
            </li>
        </ul>
    </li>

    <!-- Icons -->
    <li class="menu-item" style="display:none;">
        <a href="javascript:void(0)" class="menu-link menu-toggle">
            <i class="menu-icon tf-icons ti ti-brand-tabler"></i>
            <div data-i18n="Icons">Icons</div>
        </a>
        <ul class="menu-sub">
            <li class="menu-item">
                <a href="icons-tabler.html" class="menu-link">
                    <div data-i18n="Tabler">Tabler</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="icons-font-awesome.html" class="menu-link">
                    <div data-i18n="Fontawesome">Fontawesome</div>
                </a>
            </li>
        </ul>
    </li>

    <!-- Forms & Tables -->
    <!-- 
    <li class="menu-header small text-uppercase">
        <span class="menu-header-text">Forms &amp; Tables</span>
    </li>
    -->
    <!-- Forms -->
    <li class="menu-item" style="display:none;">
        <a href="javascript:void(0);" class="menu-link menu-toggle">
            <i class="menu-icon tf-icons ti ti-toggle-left"></i>
            <div data-i18n="Form Elements">Form Elements</div>
        </a>
        <ul class="menu-sub">
            <li class="menu-item">
                <a href="forms-basic-inputs.html" class="menu-link">
                    <div data-i18n="Basic Inputs">Basic Inputs</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="forms-input-groups.html" class="menu-link">
                    <div data-i18n="Input groups">Input groups</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="forms-custom-options.html" class="menu-link">
                    <div data-i18n="Custom Options">Custom Options</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="forms-editors.html" class="menu-link">
                    <div data-i18n="Editors">Editors</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="forms-file-upload.html" class="menu-link">
                    <div data-i18n="File Upload">File Upload</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="forms-pickers.html" class="menu-link">
                    <div data-i18n="Pickers">Pickers</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="forms-selects.html" class="menu-link">
                    <div data-i18n="Select & Tags">Select &amp; Tags</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="forms-sliders.html" class="menu-link">
                    <div data-i18n="Sliders">Sliders</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="forms-switches.html" class="menu-link">
                    <div data-i18n="Switches">Switches</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="forms-extras.html" class="menu-link">
                    <div data-i18n="Extras">Extras</div>
                </a>
            </li>
        </ul>
    </li>
    <li class="menu-item" style="display:none;">
        <a href="javascript:void(0);" class="menu-link menu-toggle">
            <i class="menu-icon tf-icons ti ti-layout-navbar"></i>
            <div data-i18n="Form Layouts">Form Layouts</div>
        </a>
        <ul class="menu-sub">
            <li class="menu-item">
                <a href="form-layouts-vertical.html" class="menu-link">
                    <div data-i18n="Vertical Form">Vertical Form</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="form-layouts-horizontal.html" class="menu-link">
                    <div data-i18n="Horizontal Form">Horizontal Form</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="form-layouts-sticky.html" class="menu-link">
                    <div data-i18n="Sticky Actions">Sticky Actions</div>
                </a>
            </li>
        </ul>
    </li>
    <li class="menu-item" style="display:none;">
        <a href="javascript:void(0);" class="menu-link menu-toggle">
            <i class="menu-icon tf-icons ti ti-text-wrap-disabled"></i>
            <div data-i18n="Form Wizard">Form Wizard</div>
        </a>
        <ul class="menu-sub">
            <li class="menu-item">
                <a href="form-wizard-numbered.html" class="menu-link">
                    <div data-i18n="Numbered">Numbered</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="form-wizard-icons.html" class="menu-link">
                    <div data-i18n="Icons">Icons</div>
                </a>
            </li>
        </ul>
    </li>
    <li class="menu-item" style="display:none;">
        <a href="form-validation.html" class="menu-link">
            <i class="menu-icon tf-icons ti ti-checkbox"></i>
            <div data-i18n="Form Validation">Form Validation</div>
        </a>
    </li>
    <!-- Tables -->
    <li class="menu-item" style="display:none;">
        <a href="tables-basic.html" class="menu-link">
            <i class="menu-icon tf-icons ti ti-table"></i>
            <div data-i18n="Tables">Tables</div>
        </a>
    </li>
    <li class="menu-item" style="display:none;">
        <a href="javascript:void(0);" class="menu-link menu-toggle">
            <i class="menu-icon tf-icons ti ti-layout-grid"></i>
            <div data-i18n="Datatables">Datatables</div>
        </a>
        <ul class="menu-sub">
            <li class="menu-item">
                <a href="tables-datatables-basic.html" class="menu-link">
                    <div data-i18n="Basic">Basic</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="tables-datatables-advanced.html" class="menu-link">
                    <div data-i18n="Advanced">Advanced</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="tables-datatables-extensions.html" class="menu-link">
                    <div data-i18n="Extensions">Extensions</div>
                </a>
            </li>
        </ul>
    </li>

    <!-- Charts & Maps -->
    <li class="menu-header small text-uppercase" style="display:none;">
        <span class="menu-header-text">Charts &amp; Maps</span>
    </li>
    <li class="menu-item" style="display:none;">
        <a href="javascript:void(0);" class="menu-link menu-toggle">
            <i class="menu-icon tf-icons ti ti-chart-pie"></i>
            <div data-i18n="Charts">Charts</div>
        </a>
        <ul class="menu-sub">
            <li class="menu-item">
                <a href="charts-apex.html" class="menu-link">
                    <div data-i18n="Apex Charts">Apex Charts</div>
                </a>
            </li>
            <li class="menu-item">
                <a href="charts-chartjs.html" class="menu-link">
                    <div data-i18n="ChartJS">ChartJS</div>
                </a>
            </li>
        </ul>
    </li>
    <li class="menu-item" style="display:none;">
        <a href="maps-leaflet.html" class="menu-link">
            <i class="menu-icon tf-icons ti ti-map"></i>
            <div data-i18n="Leaflet Maps">Leaflet Maps</div>
        </a>
    </li>

    <!-- Misc -->
    <!-- 
        <li class="menu-header small text-uppercase">
        <span class="menu-header-text">Misc</span>
    </li>
    <li class="menu-item">
        <a href="#" target="_blank" class="menu-link">
            <i class="menu-icon tf-icons ti ti-lifebuoy"></i>
            <div data-i18n="Support">Support</div>
        </a>
    </li>
    <li class="menu-item">
        <a href="#" target="_blank" class="menu-link">
            <i class="menu-icon tf-icons ti ti-file-description"></i>
            <div data-i18n="Documentation">Documentation</div>
        </a>
    </li> 
    -->
</ul>

</aside>
<!-- / Menu -->   
</template>
  
  
<script lang="ts">
import { defineComponent, onMounted } from "vue";

export default defineComponent({
    name: "adminAside",
    components: {

    },
    beforeMount() {
        //localStorage.setItem("lpg-layout", "portal");
    },
    setup() {

        onMounted(() => {
            //Apphelper.checkAdminAuthenticated();
            //alert("hello admin layout");
            //alertStore.PageLoading(false);

            console.log('This is admin aside');
        });

    }

});

</script>
  