<template>
    <!-- <span>{{ msg }}</span> -->
    <div id="wiz-enroll-content-4" class="content wiz-enroll-content">
                    <div class="content-header mb-3">
                        <h6 class="mb-0">Next of Kin</h6>
                        <small>Enter the Next of Kin details.</small>

                        <button onclick="showCanvas('#modal-add-nok');"
                                type="button" class="btn btn-outline-primary btn-sm data-submit m-1">
                            <i class="fa fa-plus"></i> &nbsp; Create Next of Kin
                        </button>
                    </div>
                    <div class="row g-3">

                        <div class="row mb-2">
                            <div class="mb-1 col-md-12">
                                <strong><small>Relationship 1</small></strong>
                                <table class="table table-striped">
                                    <tbody>
                                        <tr>
                                            <th class="text-right" style="width:120px;">Relationship</th>
                                            <td>   </td>
                                            <th style="width:120px;" class="text-right">Title</th>
                                            <td>   </td>
                                        </tr>
                                        <tr>
                                            <th class="text-right"> Full Name</th>
                                            <td>   </td>
                                            <th class="text-right">Gender</th>
                                            <td>   </td>
                                        </tr>
                                        <tr>
                                            <th class="text-right">Email</th>
                                            <td>   </td>
                                            <th class="text-right">Phone</th>
                                            <td>   </td>
                                        </tr>
                                        <tr><td colspan="4"> &nbsp; </td></tr>
                                        <tr>
                                            <th class="text-right">Country</th>
                                            <td>  </td>
                                            <th class="text-right">State</th>
                                            <td>   </td>
                                        </tr>
                                        <tr>
                                            <th class="text-right">LGA</th>
                                            <td>  </td>
                                            <th class="text-right">Village/Town</th>
                                            <td>   </td>
                                        </tr>
                                        <tr>
                                            <th class="text-right">P.O Box</th>
                                            <td>   </td>
                                            <th class="text-right">Zip Code</th>
                                            <td>   </td>
                                        </tr>
                                        <tr>
                                            <th class="text-right">Address</th>
                                            <td colspan="3">   </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div class="col-12 d-flex justify-content-between">
                            <button onclick="gotoStep('wiz-enroll', 3)" class="btn btn-label-secondary btn-prev waves-effect">
                                <i class="ti ti-arrow-left me-sm-1 me-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                            </button>
                            <button onclick="gotoStep('wiz-enroll', 5)"
                                    class="btn btn-primary btn-next waves-effect waves-light">
                                <span class="align-middle d-sm-inline-block d-none me-sm-1">Next</span> <i class="ti ti-arrow-right"></i>
                            </button>
                        </div>
                    </div>

                    <modalNok />
                </div>
 </template>
 
 <script lang="ts"> 
   import { defineComponent, onMounted } from "vue";
  import modalNok from "@/views/enrollment/partials/modalNok.vue";
  import { EnrollmentStore } from "./../EnrollmentStore";
 
 export default defineComponent({
 name: "tab4",
 components: { 
    modalNok
 },
 //props: ['msg'],
 props:{
   msg:{
     type: String,
     default: ""
   }
 },
 beforeMount() {
   //localStorage.setItem("lpg-layout", "portal");
 },
 setup(props) {
      const service = EnrollmentStore();

   onMounted(() => { 
    service.model.currentTab = 4;
     //alert("hello admin layout");
     //alertStore.PageLoading(false); 
   });
 
 }
 
 });
 
 </script>
  