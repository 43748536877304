<template>
    <!-- <span>{{ msg }}</span> -->
    <!-- Account Details -->
    <div id="wiz-enroll-content-1" class="content wiz-enroll-content active dstepper-block">
                    <div class="content-header mb-3">
                        <h6 class="mb-0">User Details</h6>
                        <small>Enter Users Account Details.</small>
                    </div>
                    <div class="row g-3">
                        <div class="col-sm-12">
                            <label class="form-label">User Agent Email</label>
                            <input type="text" name="UserAgentEmail"
                                   id="txtUserAgentEmail" jsvalidate="[type:email,]" class="form-control"
                                   placeholder="login.user@email.com" />
                        </div>
                        <div class="col-sm-12">
                            <label class="form-label" for="email-vertical">Account Officer Email</label>
                            <input type="text" name="AccountOfficerEmail" class="form-control"
                                   jsvalidate="[type:email,]" placeholder="account.officer@email.com" />
                        </div>

                        <div class="mb-1">
                            <label class="form-label required" for="ad_location">Location</label>
                            <select id="ad_location" class="form-select select2"
                                    title="Select the Location" 
                                    name="Location" required>
                                <option value="">Select Location</option>
                                <option v-for="(opt, indx) in service.listBasicDropdown.Location" :value="opt">{{ opt }}</option>
                            </select>
                        </div>

                        <div class="mb-1">
                            <label class="form-label required">Form Number</label>
                            <input type="text" class="form-control" jsvalidate="[req,type:text,]"
                                   placeholder="Enter the Form No" name="FormNo"
                                   maxlength="50" required />
                        </div>

                        <div class="col-12 d-flex justify-content-between">
                            <button class="btn btn-label-secondary not-allowed btn-prev waves-effect">
                                <i class="ti ti-arrow-left me-sm-1 me-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                            </button>
                            <button onclick="gotoStep('wiz-enroll', 2)" class="btn btn-primary btn-next waves-effect waves-light">
                                <span class="align-middle d-sm-inline-block d-none me-sm-1">Next</span>
                                <i class="ti ti-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
 </template>
 
 <script lang="ts"> 
   import { defineComponent, onMounted } from "vue";
  import { EnrollmentStore } from "./../EnrollmentStore";

 
 export default defineComponent({
 name: "tab1",
 components: { 
 },
 //props: ['msg'],
 props:{
   msg:{
     type: String,
     default: ""
   }
 },
 beforeMount() {
   //localStorage.setItem("lpg-layout", "portal");
 },
 setup(props) {
    const service = EnrollmentStore();

   onMounted(() => {
     service.model.currentTab = 1;
     //Apphelper.checkAdminAuthenticated();
     //alert("hello admin layout");
     //alertStore.PageLoading(false); 
   });
 
   return {
      service
   }
 }
 
 });
 
 </script>
  