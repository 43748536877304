<template>
    <!-- <span>{{ msg }}</span> -->
     <!-- Personal Info -->
     <div id="wiz-enroll-content-2" class="content wiz-enroll-content">
            <div class="content-header mb-3">
                <h6 class="mb-0">Personal Info</h6>
                <small>Enter the Personal Information</small>
            </div>
            <div>
                <div class="p-2">

                    <div class="row mb-2">
                        <div class="mb-1 col-md-6">
                            <label class="form-label required" for="ad_fktitle">Title</label>
                            <select id="ad_fktitle" class="form-select select2"
                                    title="Select the Title"
                                    name="FkTitle" required>
                                <option value="">Select Title</option>
                                <option v-for="(opt, indx) in service.listBasicDropdown.Title" :value="opt">{{ opt }}</option>
                            </select>
                        </div>

                        <div class="mb-1 col-md-6">
                            <label class="form-label required">Surname</label>
                            <input type="text" class="form-control" jsvalidate="[req,type:text,minlength:3]"
                                    placeholder="Enter the Surname" name="Surname"
                                    maxlength="50" required />
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="mb-1 col-md-6">
                            <label class="form-label required">First Name</label>
                            <input type="text" class="form-control" jsvalidate="[req,type:text,minlength:3]"
                                    placeholder="Enter the First Name" name="FirstName"
                                    maxlength="50" required />
                        </div>

                        <div class="mb-1 col-md-6">
                            <label class="form-label">Middle Name</label>
                            <input type="text" class="form-control" jsvalidate="[type:text,]"
                                    placeholder="Enter the Middle Name" name="MiddleName"
                                    maxlength="50" />
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="mb-1 col-md-6">
                            <label class="form-label">Maiden Name</label>
                            <input type="text" class="form-control" jsvalidate="[type:text,]"
                                    placeholder="Enter the Maiden Name" name="MaidenName"
                                    maxlength="50" />
                        </div>
                        <div class="mb-1 col-md-6">
                            <label class="form-label required" for="ad_birthdate">Birth Date</label>
                            <input type="text" name="BirthDate" id="ad_birthdate"
                                    class="form-control jsdatepicker" required readonly />
                        </div>
                    </div>



                    <div class="row mb-2">
                        <div class="mb-1 col-md-6">
                            <label class="form-label required" for="ad_fkmarital">Marital Status</label>
                            <select id="ad_fkmarital" class="form-select select2" title="Select the Marital"
                                    name="FkMarital" required>
                                <option value="">Select Marital</option>
                                <option v-for="(opt, indx) in service.listBasicDropdown.Marital" :value="opt">{{ opt }}</option>
                            </select>
                        </div>

                        <div class="mb-1 col-md-6">
                            <label class="form-label required" for="ad_fkgender">Gender</label>
                            <select id="ad_fkgender" class="form-select select2" title="Select the Gender"
                                    name="FkGender" required>
                                <option value="">Select Gender</option>
                                <option v-for="(opt, indx) in service.listBasicDropdown.Gender" :value="opt">{{ opt }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="mb-1 col-md-6">
                            <label class="form-label">Email</label>
                            <input type="email" class="form-control"
                                    placeholder="Enter the Email" name="Email"
                                    jsvalidate="[type:email,]"
                                    maxlength="50" />
                        </div>

                        <div class="mb-1 col-md-6">
                            <label class="form-label">Phone</label>
                            <input type="text" class="form-control" jsvalidate="[type:text,]"
                                    placeholder="Enter the Phone" name="Phone"
                                    maxlength="50" />
                        </div>
                    </div>


                    <div class="content-header mt-4">
                        <h6 class="mb-0">Nationality Information</h6>
                        <small>Enter the nationality Information</small>
                    </div>
                    <div class="row mb-2">

                        <!--  <Country Id="aSelCountry" Name="SelCountry" StateId="selOptState" />
                        <select id="selOptState">
                            <option value="">Select State</option>
                        </select> -->

                        <div class="mb-1 col-md-6">
                            <label class="form-label required" for="ad_fkcountry_enroller">Country</label>
                            <select id="ad_fkcountry_enroller" name="FkCountryEnroller" class="form-control select2"
                                    onchange="fxnLoadCountryStates('#ad_fkcountry_enroller', '#ad_fkstate_enroll', '#ad_fklga_enroll', 'select2');">
                                <option value="">Select Country</option>
                                <option v-for="(opt,indx) in serviceCountry.listCountries" :value="opt.code3">{{ opt.name }}</option>
                            </select> 
                        </div>

                        <div class="mb-1 col-md-6">
                            <label class="form-label required" for="ad_fkstate_enroll">State</label>
                            <select id="ad_fkstate_enroll" class="form-select select2"
                                    title="Select the State" onchange="fxnLoadStateLGAs('#ad_fkstate_enroll','#ad_fklga_enroll', 'select2');"
                                    name="FkStateEnroll" required>
                                <option value="">Select State</option>
                            </select>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="mb-1 col-md-6">
                            <label class="form-label required" for="ad_fklga_enroll">LGA</label>
                            <select id="ad_fklga_enroll" class="form-select select2" title="Select the LGA"
                                    name="FkLgaEnroll" required>
                                <option value="">Select LGA</option>
                            </select>
                        </div>

                        <div class="mb-1 col-md-6">
                            <label class="form-label">Birth Place</label>
                            <input type="text" class="form-control" jsvalidate="[type:text,]"
                                    placeholder="Enter the Birth Place" name="BirthPlace"
                                    maxlength="50" />
                        </div>
                    </div>


                    <div class="content-header mt-4">
                        <h6 class="mb-0">Identification Information</h6>
                        <small>Enter the identification Information</small>
                    </div>
                    <div class="row mb-2">
                        <div class="mb-1 col-md-6">
                            <label class="form-label">NIN</label>
                            <input type="text" class="form-control" jsvalidate="[type:text,]"
                                    placeholder="Enter the Nin" name="Nin"
                                    maxlength="50" />
                        </div>
                        <div class="mb-1 col-md-6">
                            <label class="form-label">BVN</label>
                            <input type="text" class="form-control" jsvalidate="[type:text,]"
                                    placeholder="Enter the Bvn" name="Bvn"
                                    maxlength="50" />
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="mb-1 col-md-6">
                            <label class="form-label">Virtual NIN</label>
                            <input type="text" class="form-control" jsvalidate="[type:text,]"
                                    placeholder="Enter the Virtual NIN" name="Vnin"
                                    maxlength="50" />
                        </div>

                        <div class="mb-1 col-md-6">
                            <label class="form-label">Virtual NIN USERID</label>
                            <input type="text" class="form-control" jsvalidate="[type:text,]"
                                    placeholder="Enter the Virtual NIN USERID" name="VninUserid"
                                    maxlength="50" />
                        </div>
                    </div>

                    <div class="content-header mt-4">
                        <h6 class="mb-0">Residential Information</h6>
                        <small>Enter the residential Information</small>

                        <button onclick="showCanvas('#modal-add-address');"
                                type="button" class="btn btn-outline-primary btn-sm data-submit m-1">
                            <i class="fa fa-plus"></i> &nbsp; Create
                        </button>
                    </div>
                    <div class="row mb-2">
                        <div class="mb-1 col-md-12">
                            <table class="table table-striped">
                                <tbody>
                                    <tr>
                                        <th class="text-right" style="width:120px;">Country</th>
                                        <td>  </td>
                                        <th style="width:120px;" class="text-right">State</th>
                                        <td> </td>
                                    </tr>
                                    <tr>
                                        <th class="text-right">LGA</th>
                                        <td>   </td>
                                        <th class="text-right">Village/Town</th>
                                        <td>   </td>
                                    </tr>
                                    <tr>
                                        <th class="text-right">P.O Box</th>
                                        <td>   </td>
                                        <th class="text-right">Zip Code</th>
                                        <td>   </td>
                                    </tr>
                                    <tr>
                                        <th class="text-right">Address</th>
                                        <td colspan="3">   </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="col-12 mt-3 d-flex justify-content-between">
                        <button onclick="gotoStep('wiz-enroll', 2)" class="btn btn-label-secondary btn-prev waves-effect">
                            <i class="ti ti-arrow-left me-sm-1 me-0"></i>
                            <span class="align-middle d-sm-inline-block d-none">Previous</span>
                        </button>
                        <button onclick="gotoStep('wiz-enroll', 3)"
                                class="btn btn-primary btn-next waves-effect waves-light">
                            <span class="align-middle d-sm-inline-block d-none me-sm-1">Next</span> <i class="ti ti-arrow-right"></i>
                        </button>
                    </div>

                </div>
            </div>
      </div>
 
 </template>
 
 <script lang="ts"> 
   import { defineComponent, onMounted } from "vue";
  import modalAddress from "@/views/enrollment/partials/modalAddress.vue";
  import { EnrollmentStore } from "./../EnrollmentStore";
  import { CountryStore } from "./../CountryStore";
 
 export default defineComponent({
 name: "tab2",
 components: { 
      modalAddress
 },
 //props: ['msg'],
 props:{
   msg:{
     type: String,
     default: ""
   }
 },
 beforeMount() {
   //localStorage.setItem("lpg-layout", "portal");
 },
 setup(props) {
    const service = EnrollmentStore();
    const serviceCountry = CountryStore();

   onMounted(() => {
     serviceCountry.Init();
     service.model.currentTab = 2;
     //Apphelper.checkAdminAuthenticated();
     //alert("hello admin layout");
     //alertStore.PageLoading(false); 
   });
 
   return {
      service,
      serviceCountry
   }
 }
 
 });
 
 </script>
  