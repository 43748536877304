<template>
    <!-- <span>{{ msg }}</span> -->
    <div id="wiz-enroll-content-5" class="content wiz-enroll-content">
                    <div class="content-header mb-3">
                        <h6 class="mb-0">Pension Information</h6>
                        <small>Enter the Pension Data</small>
                    </div>
                    <div class="row g-3">
                        <div class="row">
                            <div class="mb-1">
                                <label class="form-label" for="ad_fk_penscheme">Pension Scheme</label>
                                <select id="ad_fk_penscheme" class="form-select select2"
                                        title="Select the Pension Scheme"
                                        name="PensionScheme">
                                    <option value="">Select Pension Scheme</option>
                                    <option v-for="(opt, indx) in service.listBasicDropdown.PensionScheme" :value="opt">{{ opt }}</option>
                                </select>
                            </div>

                            <div class="mb-1">
                                <label class="form-label">Salary Scale</label>
                                <input type="text" class="form-control" jsvalidate="[type:number,]"
                                       placeholder="Enter the Salary Scale" name="SalaryScale"
                                       maxlength="150" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="mb-1 col-md-6">
                                <label class="form-label">Grade Level</label>
                                <select id="ad_pension_gradelevel" class="form-select select2"
                                        title="Select the Grade Level" name="PensionGradeLevel">
                                    <option value="">Select Grade Level</option>
                                    <option v-for="(opt, indx) in service.listBasicDropdown.GradeLevel" :value="opt">{{ opt }}</option>
                                </select>
                            </div>

                            <div class="mb-1 col-md-6">
                                <label class="form-label">Grade Step</label>
                                <select id="ad_fkgradelevel" class="form-select select2"
                                        title="Select the Grade Step" name="FkGradeStep">
                                    <option value="">Select Grade Step</option>
                                    <option v-for="(opt, indx) in service.listBasicDropdown.GradeStep" :value="opt">{{ opt }}</option>
                              </select>
                            </div>
                        </div>

                        <div class="row">
                            <div class="mb-1 col-md-6">
                                <label class="form-label" for="ad_basic_allowance">Basic Allowance</label>
                                <input type="text" name="BasicAllowance" id="ad_basic_allowance"
                                       class="form-control" jsvalidate="[type:number,]" />
                            </div>
                            <div class="mb-1 col-md-6">
                                <label class="form-label" for="ad_transport_allowance">Transport Allowance</label>
                                <input type="text" name="TransportAllowance" id="ad_transport_allowance"
                                       class="form-control" jsvalidate="[type:number,]" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="mb-1 col-md-6">
                                <label class="form-label" for="ad_housing_allowance">Housing Allowance</label>
                                <input type="text" name="HousingAllowance" id="ad_housing_allowance"
                                       class="form-control" jsvalidate="[type:number,]" />
                            </div>
                            <div class="mb-1 col-md-6">
                                <label class="form-label" for="ad_other_allowance">Other Allowance</label>
                                <input type="text" name="OtherAllowance" id="ad_other_allowance"
                                       class="form-control" jsvalidate="[type:number,]" />
                            </div>
                        </div>



                        <div class="content-header mt-4">
                            <h6 class="mb-0">Micro Pensions (Only)</h6>
                            <small>Enter the micro pensions details</small>
                        </div>

                        <div class="row">
                            <div class="mb-1 col-md-6">
                                <label class="form-label" for="ad_fksector">Bank</label>
                                <select id="ad_fkbank" class="form-select select2" title="Select the Bank"
                                        name="Bank">
                                    <option value="">Select Bank</option>
                                    <option v-for="(opt, indx) in service.listBasicDropdown.Bank" :value="opt">{{ opt }}</option>
                            </select>
                            </div>
                            <div class="mb-1 col-md-6">
                                <label class="form-label" for="ad_other_allowance">Bank Branch</label>
                                <select id="ad_fkbankbranch" class="form-select select2" title="Select the Bank Branch"
                                        name="Bank">
                                    <option value="">Select Bank Branch</option>
                                </select>
                            </div>
                        </div>

                        <div class="row">
                            <div class="mb-1 col-md-6">
                                <label class="form-label" for="ad_account_name">Account Name</label>
                                <input type="text" name="AccountName" id="ad_account_name"
                                       class="form-control" jsvalidate="[type:text,]" />
                            </div>
                            <div class="mb-1 col-md-6">
                                <label class="form-label" for="ad_account_number">Account Number</label>
                                <input type="text" name="AccountNumber" id="ad_account_number"
                                       class="form-control" jsvalidate="[type:number,maxlength:10]" />
                            </div>
                        </div>




                        <div class="col-12 d-flex justify-content-between">
                            <button onclick="gotoStep('wiz-enroll', 4)" class="btn btn-label-secondary btn-prev waves-effect">
                                <i class="ti ti-arrow-left me-sm-1 me-0"></i>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                            </button>
                            <button onclick="gotoStep('wiz-enroll', 6)"
                                    class="btn btn-primary btn-next waves-effect waves-light">
                                <span class="align-middle d-sm-inline-block d-none me-sm-1">Next</span> <i class="ti ti-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
 </template>
 
 <script lang="ts"> 
   import { defineComponent, onMounted } from "vue";
  import { EnrollmentStore } from "./../EnrollmentStore";
 
 export default defineComponent({
 name: "tab5",
 components: { 
 },
 //props: ['msg'],
 props:{
   msg:{
     type: String,
     default: ""
   }
 },
 beforeMount() {
   //localStorage.setItem("lpg-layout", "portal");
 },
 setup(props) {
      const service = EnrollmentStore();

   onMounted(() => {
        service.model.currentTab = 5;
     //Apphelper.checkAdminAuthenticated();
     //alert("hello admin layout");
     //alertStore.PageLoading(false); 
   });
 

   return {
     service
   }
 }
 
 });
 
 </script>
  