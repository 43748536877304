import { ref, computed } from 'vue';
import { defineStore } from 'pinia'; 
 
const appJs = require('@/assets/app-service.js');

export interface IBasicDropdown{
    Bank: string[];    
    Title: string[];
    Gender: string[];
    Marital: string[];
    Relationship: string[];
    EmployerType: string[];
    Location: string[];    
    PensionScheme: string[];    
    GradeLevel: string[];    
    GradeStep: string[];    
}

export const EnrollmentStore = defineStore('StoreEnrollment', () => {
  const apiPath = "/api/Product";

  const model = ref({
    currentTab: 1,
    //dropdown: <IBasicDropdown | null>{},
    //-----------------
    //disableBulkAdd: false,
  });

  const listDropdown = ref<IBasicDropdown>({} as any);
    
  const _getDropdownData = () => {
    var list = appJs.fxnDropdownJsonData();
    listDropdown.value = list; 
    return list;
  }
   
  return {
    model,
    listBasicDropdown: _getDropdownData() 
  }

});

    