"use strict";
  
/*
//============= has-success, has-warning, has-error =======
.form-group.has-success .form-control,
.form-group.has-success .input-group-addon {
    border-color: #00a65a;
    box-shadow: none;
}
//----------------------
.red{ color:red; }
.green{ color:green; }
.required:after{ content:'*' !important; color:red;font: bold 14px; display:inline;}
.cursor { cursor: pointer !important;}
*/


//Disable Right-Click 
export function _PreventRightclick() {
    /*
    window.addEventListener('contextmenu', function (e) {
        document.body.innerHTML += '<p>Right-click is disabled</p>'; e.preventDefault();
    }, false);
    $('body').bind('cut copy paste', function (e) { e.preventDefault(); });

    window.history.forward(); 
    window.onpageshow = function (evt) { if (evt.persisted) window.history.forward(); }; window.onunload = function () { void (0); };
    */
}

 
export const PageLoading = function () {
    let loader = "#loader-wrapper";
    return {
        Show: function () { /*$("#preloader").show(); $(loader).fadeIn(30);*/ $(loader).show(30); },
        Hide: function () { /*$("#preloader").hide(); $(loader).fadeOut(30); */ $(loader).hide();  },
        NoAccess: function () { PageLoading.Show(); window.location.href = "/PageNotFound"; }
    }
}();

export const Toastr = function () {
    return {
        Success: function (text, title, duration) { this._BaseMessage('success', text, title, duration); },
        Info: function (text, title, duration) { this._BaseMessage('info', text, title, duration); },
        Error: function (text, title, duration) { this._BaseMessage('error', text, title, duration); },
        Danger: function (text, title, duration) { this._BaseMessage('error', text, title, duration); },
        Warning: function (text, title, duration) { this._BaseMessage('warning', text, title, duration); },

        _BaseMessage: function (type, text, title, duration) {
            /*  toastr.success("You have successfully logged in to Innovation Knowledge. Now you can start to explore!",
            "👋 Welcome John Doe!", { closeButton: !0, tapToDismiss: !1, rtl: w }) */
            title = (title || "");
            text = (text || "");
            duration = (duration || 1000);
            //error, success, info, warning
            toastr.options = {
                "closeButton": true,
                "debug": false,
                "newestOnTop": true,
                "progressBar": true,
                "rtl": false,
                "positionClass": "toast-top-right",
                "preventDuplicates": true,
                "onclick": null,
                "showDuration": 300,
                "hideDuration": duration, //1000,
                "timeOut": 3000,
                "extendedTimeOut": 1000,
                "showEasing": "swing",
                "hideEasing": "linear",
                "showMethod": "fadeIn",
                "hideMethod": "fadeOut"
            };
            toastr[type](text, title);
        },
    };
}();

export const Flash = function () {
    return {
        RemoveFlashMessage: function () { $("#div_flash,.div_flash").html(""); },

        Success: function (control, text) { this._BaseMessage(control, text, 'success', 'check'); },
        Info: function (control, text) { this._BaseMessage(control, text, 'info bg-info text-white', 'note'); },
        Error: function (control, text) { this._BaseMessage(control, text, 'danger', 'cancel'); },
        Danger: function (control, text) { this._BaseMessage(control, text, 'danger', 'cancel'); },
        Warning: function (control, text) { this._BaseMessage(control, text, 'warning', 'exclamation'); },
        NoLogRecord: function () { this._BaseMessage("#div_flash", "No Record...", 'warning', 'exclamation'); },

        Guid: function (len) {
            let buf = [], chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789', charlen = chars.length, length = len || 32;
            for (var i = 0; i < length; i++) { buf[i] = chars.charAt(Math.floor(Math.random() * charlen)); }
            return buf.join(''); //Flash.Guid();  Flash.Guid(10);
        },
        //flash-message 
        _BaseMessage: function (control, text, type, icon) {
            control = control || "";
            text = text || "";
            if (text === "" || text == 'undefined') {  //using 
                let newText = control;
                control = "#div_flash";
                text = newText;
            }

            let flash_id = this.Guid(10); //type=>success,info,danger,warning  
            text = `<div id='flash_${flash_id}' class="alert alert-${type} flash-message m-1 p-1"> 
                        <button type="button" class="btn-close" data-dismiss="alert" aria-label="Close"> </button>
                        <span> <i class="fa fa-${icon}"></i>  ${text} </span> 
                    </div>`;
            $(control).append(text);
            //setTimeout(function () { $('#flash_' + flash_id).fadeOut(); }, 10000);
            let div_flashes = '#flash_' + flash_id;
            setTimeout(function () { $(div_flashes).slideUp(); $(div_flashes).html(""); $(div_flashes).remove(); }, 10000);
        },

        FeedBack: function (control, text) {
            let flash_id = this.Guid(10); //type=>success,info,danger,warning
            let new_text = $(`<div id='flash_${flash_id}' class="alert alert-info flash-message m-1 p-1"> 
                            <button type="button" class="btn-close" data-dismiss="alert" aria-label="Close"> </button>
                            <span> ${text} </span> </div>`).hide();

            $(control).prepend(new_text);
            new_text.show('normal');
        },
        FeedBackError: function (control, text) {
            let flash_id = this.Guid(10); //type=>success,info,danger,warning
            let new_text = $(`<div id='flash_${flash_id}' class="alert alert-danger flash-message m-1 p-1">
                    <button type="button" class="btn-close" data-dismiss="alert" aria-label="Close"> </button>
                    <span> <i class="fa fa-exclamation"></i> ${text} </span>   </div>`).hide();
            $(control).prepend(new_text);
            new_text.show('normal');
        }
    };
}();

export const SwalAlert = function () {
    return {
        Success: function (text, title) { this._BaseAlert(text, title, 'success'); },
        Info: function (text, title) { this._BaseAlert(text, title, 'info'); },
        Error: function (text, title) { this._BaseAlert(text, title, 'error'); },
        Danger: function (text, title) { this._BaseAlert(text, title, 'error'); },
        Warning: function (text, title) { this._BaseAlert(text, title, 'warning'); },
        _BaseAlert: function (text, title, color) { Swal.fire(title, text, color); },

        Confirm: function (title, text, fxnResult) {
            Swal.fire({
                title: title,
                text: text,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            }).then((result) => { if (result.value) { fxnResult(); } });
            /*  SwalAlert.Confirm("Confirm Delete", "Are you sure you want to delete?", function (result) {
                    console.log('The Result: ', result); 
                });   */
        },
        Input: function (title, text, fxnResult) {
            Swal.fire({
                title: title,
                text: text,
                icon: 'question',
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Ok',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                showLoaderOnConfirm: true,
                //preConfirm: (login) => {
                //    console.log('PreConfirmLogin:', login);
                //    //return fetch(`//api.github.com/users/${login}`).then(response => {
                //    //        if (!response.ok) {  throw new Error(response.statusText);  }
                //    //        return response.json();
                //    //    }).catch(error => {  Swal.showValidationMessage(  `Request failed: ${error}` );   });
                //},
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (result.value) {
                    //console.log('Result:', result.value);
                    fxnResult(result.value);
                    //Swal.fire({ title: `${result.value.login}'s avatar`, imageUrl: result.value.avatar_url  });
                }
            });
        }
    };
}();

export const Logger = function () {
    return {
        Success: function (obj, msg) { this._Write(obj, msg, 'success'); },
        Info: function (obj, msg) { this._Write(obj, msg, 'info'); },
        Warning: function (obj, msg) { this._Write(obj, msg, 'warning'); },
        Danger: function (obj, msg) { this._Write(obj, msg, 'danger'); },

        _Write: function (err, msg, type) {
            //TODO: how to log errors to api
            //var str_obj = JSON.stringify(obj);
            try {

            } catch (err) { }
        }
    };
}();
 


export const JsStorage = function () {
    return {
        SetObject: function (key, obj) { var jsonObj = JSON.stringify(obj); this.SetString(key, jsonObj); },
        SetString: function (key, strValue) { window.localStorage.setItem(key, strValue); },
        GetObject: function (key) { var valu = this.GetString(key); return JSON.parse(valu); },
        GetString: function (key) { return window.localStorage.getItem(key); },
        Remove: function (key) { window.localStorage.removeItem(key); },
        ClearAll: function () { window.localStorage.clear(); }
    }
}();

export const AppForm = function () {
    let isErrorInAlert = false; //true=>Alert Error   false=>Display Error under the control.
    let _controlId = "";
    let createControlErrorSpan = function (control, error_message) {
        let id = (($(control).prop("id") || "") === "") ? ($(control).attr("v-model") || "") : $(control).prop("id");
        let spanErrorId = id.replace(".", "_") + "_err";
        try { $("#" + spanErrorId).remove(); } catch (ex) { }
        $(control).after('<span id="' + spanErrorId + '" class="error invalid-feedback"></span>');
        _controlId = spanErrorId;
        return spanErrorId;
    };

    let setError = function (control, obj, isValid, error_message) {

        $(control).removeClass("is-invalid");
        $(control).removeClass("is-valid");

        error_message = error_message || "";
        obj.isValid = isValid;
        try {
            var controlId = createControlErrorSpan(control, error_message);
            console.log('Error MEssage:', error_message);

            //var text = (isValid) ? "<i class='fa fa-check text-success'></i>" : "<i class='fa fa-times text-danger'></i>";
            $("#" + controlId).html(error_message);
            $("#" + controlId).attr("title", error_message);

            if (!isValid) {
                Flash.Error(control, error_message);
                $(control).addClass("is-invalid");
            }
            else {
                $(control).addClass("is-valid");
            }
        } catch (ex) { }
        /*  <input type="text" id="v_ToDate23" class="form-control is-invalid" />   <span class="error invalid-feedback">hello error</span> */
        return obj;
    };
    let _IsControlValid = function (control) {
        var obj = {
            id: "", type: "", isRequired: false,
            value: "", validation_type: "", msg: "",
            min: "", max: "", minLength: "", maxLength: "",
            equalToId: "", notEqualToId: "", lessThanId: "", greaterThanId: "", lessThanEqualToId: "", greaterThanEqualToId: "",
            labelText: ""
        };
        if (!$(control).is(":visible")) {
            console.log('control not visible, ', obj);
            return setError(control, obj, true);
        }
        // date, datetime, email, number, range, tel/phone, url, file, password, password_strong, text, name
        // validation-type="number"  min="" max="" minlength="" maxlength=""  title="" equal-to-id=""(eq-id) notequal-to-id=""(neq-id)
        // lt-id=""(less-than-id) gt-id=""(greater-than-id)  lte-id=""(less-than-equal-id) gte-id=""(greater-than-equal-id)


        obj.id = $(control).prop("id") || ""; if (obj.id.indexOf("{") > 0) return setError(control, obj, true);
        obj.type = $.trim($(control).prop("type") || ""); if (obj.type.indexOf("select") >= 0) obj.type = "select";

        obj.isRequired = $(control).prop("required") || false;
        obj.min = $(control).attr("min") || ""; obj.max = $(control).attr("max") || "";
        obj.minLength = $(control).attr("minlength") || ""; obj.maxLength = $(control).attr("maxlength") || "";

        obj.equalToId = $(control).attr("eq-id") || ($(control).attr("equal-to-id") || "");
        obj.notEqualToId = $(control).attr("neq-id") || ($(control).attr("notequal-to-id") || "");
        obj.lessThanId = $(control).attr("lt-id") || ($(control).attr("less-than-id") || "");
        obj.greaterThanId = $(control).attr("gt-id") || ($(control).attr("greater-than-id") || "");
        obj.lessThanEqualToId = $(control).attr("lte-id") || ($(control).attr("less-than-equal-id") || "");
        obj.greaterThanEqualToId = $(control).attr("gte-id") || ($(control).attr("greater-than-equal-id") || "");
        let inputID = $(control).prop("id"); obj.labelText = $('label[for="' + inputID + '"]').text() || "";

        //obj.msg = (obj.msg === "") ? ($(control).attr("title") || "") : obj.msg;  //obj.msg = (obj.msg === "") ? "fill in the compulsory field(s)..." + obj.id : obj.msg;  //if (obj.msg.toLowerCase().indexOf("please") < 0) obj.msg = "Please " + obj.msg;
        let valu = $(control).prop("value") || ""; if (valu.indexOf("undefined:undefined") > 0) { valu = ""; } obj.value = $.trim(valu);
        obj.validation_type = $.trim(($(control).attr("validation-type") || ""));

        if (obj.validation_type === "undefined" || obj.validation_type === "") {
            var arrValidators = "date,email,number,range,tel,phone,url,web,name".split(",");
            if ($.inArray(obj.type, arrValidators) >= 0) obj.validation_type = obj.type; else obj.validation_type = "text";
        }
        //var obj = { id:"", type:"", isRequired:false, min:0, max:0, value:"", validation_type:"", msg:"" };
        //alert(JSON.stringify(obj)); //  minlength="4" maxlength="8"
        //console.log('object form: ', obj);
        if (obj.type == "select") {
            if (obj.isRequired == true && obj.value == "")
                return setError(control, obj, false, `${obj.labelText} is required`);

            //try { $("#"+obj.id).val(obj.value).trigger('change'); } catch (xxx) { }
            return setError(control, obj, true);
        }

        if (obj.value === "" && !obj.isRequired) return setError(control, obj, true);
        if (obj.value === "" && obj.isRequired) return setError(control, obj, false, `${obj.labelText} is required`);

        if ((obj.validation_type === "number") && isNaN(obj.value)) {
            console.log("obj_numeric:", obj);
            $(control).prop("value", ""); return setError(control, obj, false, `${obj.labelText} requires numeric value...`);
        }

        //test for minimum
        if (obj.min != "") {
            let minValu = parseFloat(obj.min);
            if (isNaN(obj.value)) { return setError(control, obj, false, `${obj.labelText} requires numeric value..`); }
            let amt = parseFloat(obj.value);
            if (amt < minValu) {
                return setError(control, obj, false, `${obj.labelText} minimum allowed is ${minValu}`);
            }
        }
        //test for maximum
        if (obj.max != "" && (obj.type == 'number' || obj.type != 'date')) {
            let maxValu = parseFloat(obj.max);
            if (isNaN(obj.value)) { return setError(control, obj, false, `${obj.labelText} requires numeric value.`); }
            let amt = parseFloat(obj.value);
            if (amt > maxValu) {
                return setError(control, obj, false, `${obj.labelText} maximum allowed is ${minValu}`);
            }
        }

        // validation-type="number"  min="" max="" minlength="" maxlength=""  title=""
        //equal-to-id="" eq-id="" lt-id="" gt-id="" lt-id="" lte-id="" gte-id=""

        //test for minlength and maxlength
        if (obj.minLength != "") {
            let minLength = parseInt(obj.minLength);
            if (minLength > 0 && obj.value.length < minLength) { return setError(control, obj, false, `${obj.labelText} requires minimum length of ${minLength} characters`); }
        }
        if (obj.maxLength != "") {
            let maxLength = parseInt(obj.maxLength);
            if (maxLength > 0 && obj.value.length > maxLength) { return setError(control, obj, false, `${obj.labelText} requires maximum length of ${maxLength} characters`); }
        }

        // test for equalToId: "",  notEqualToId: "", lessThanId: "",  greaterThanId: "", lessThanEqualToId: "", greaterThanEqualToId: "",
        if (obj.equalToId != "") {
            let idValue = $.trim($(obj.equalToId).val() || "");
            if (obj.value != idValue) {
                let idLabelText = $('label[for="' + obj.equalToId.replace('#', '') + '"]').text() || "";
                return setError(control, obj, false, `${obj.labelText} is not the same with ${idLabelText}`);
            }
        }
        if (obj.notEqualToId != "") {
            let idValue = $.trim($(obj.notEqualToId).val() || "");
            if (obj.value == idValue) {
                let idLabelText = $('label[for="' + obj.notEqualToId.replace('#', '') + '"]').text() || "";
                return setError(control, obj, false, `${obj.labelText} is the same with ${idLabelText}`);
            }
        }
        if (obj.lessThanId != "") {
            let idValue = parseFloat($.trim($(obj.lessThanId).val() || ""));
            let objValue = parseFloat(obj.value);
            if (objValue >= idValue) {
                let idLabelText = $('label[for="' + obj.lessThanId.replace('#', '') + '"]').text() || "";
                return setError(control, obj, false, `${obj.labelText} is not lesser than ${idLabelText}`);
            }
        }
        if (obj.greaterThanId != "") {
            let idValue = parseFloat($.trim($(obj.greaterThanId).val() || ""));
            let objValue = parseFloat(obj.value);
            if (objValue <= idValue) {
                let idLabelText = $('label[for="' + obj.greaterThanId.replace('#', '') + '"]').text() || "";
                return setError(control, obj, false, `${obj.labelText} is not greater than ${idLabelText}`);
            }
        }
        if (obj.lessThanEqualToId != "") {
            let idValue = parseFloat($.trim($(obj.lessThanEqualToId).val() || ""));
            let objValue = parseFloat(obj.value);
            if (objValue > idValue) {
                let idLabelText = $('label[for="' + obj.lessThanEqualToId.replace('#', '') + '"]').text() || "";
                return setError(control, obj, false, `${obj.labelText} is not lesser than ${idLabelText}`);
            }
        }
        if (obj.greaterThanEqualToId != "") {
            let idValue = parseFloat($.trim($(obj.greaterThanEqualToId).val() || ""));
            let objValue = parseFloat(obj.value);
            if (objValue < idValue) {
                let idLabelText = $('label[for="' + obj.greaterThanEqualToId.replace('#', '') + '"]').text() || "";
                return setError(control, obj, false, `${obj.labelText} is not greater than ${idLabelText}`);
            }
        }

        //if (obj.min > 0 && obj.value.length < obj.min && (obj.validation_type !== "number")) return setError(control, obj, false);
        if (obj.validation_type === "name") return setError(control, obj, GlobalService.IsName(valu), `${obj.labelText} is not valid`);
        if (obj.validation_type === "email" || obj.type === "email") return setError(control, obj, GlobalService.IsEmail(valu), `${obj.labelText} is not valid`);
        if (obj.validation_type === "url" || obj.validation_type === "web") return setError(control, obj, GlobalService.IsURL(valu), `${obj.labelText} is not valid`);
        if (obj.validation_type === "phone" || obj.validation_type === "tel" || obj.type === "phone") return setError(control, obj, GlobalService.IsPhone(valu), `${obj.labelText} is not valid`);
        if (obj.validation_type === "date" || obj.type === "date") return setError(control, obj, GlobalService.IsDate(valu), `${obj.labelText} is not valid`);
        if (obj.validation_type === "datetime" || obj.type === "datetime") return setError(control, obj, GlobalService.IsDate(valu), `${obj.labelText} is not valid`);

        if (obj.type === "password" && obj.validation_type === "text" && obj.min > 0) return setError(control, obj, GlobalService.IsPassword(obj.value, obj.min), `${obj.labelText} is not valid`);
        if (obj.type === "password" && (obj.validation_type === "password_strong" || obj.validation_type === "password-strong"))
            return setError(control, obj, GlobalService.IsPasswordStrong(obj.value, obj.min), `${obj.labelText} is not valid, enter a strong password`);

        return setError(control, obj, true, "");
    };
    // ReSharper disable once InconsistentNaming  input[type=hidden],
    let NOT_CONTROL_TYPES = "input[type=button],input[type=reset],input[type=submit],input[type=file],button,input[type=checkbox],input[type=radio]";
    let _ClearValidation = function (form_control) {
        $(form_control).find("input,textarea,select").not(NOT_CONTROL_TYPES).each(function () {
            createControlErrorSpan(this);
            $(this).removeClass("is-invalid");
            $(this).removeClass("is-valid");
        });
    };
    let _BindEventToControl = function (formId) {
        $(formId).find("input,textarea,select").not(NOT_CONTROL_TYPES).each(function () {
            try {
                const _controlID = $(this).prop("id") || "";
                if (_controlID.indexOf("{") < 0) {
                    if (($(this).prop("type")).toLowerCase().indexOf("select") >= 0) {
                        $(this).change(function () { _IsControlValid(this); });
                    } else { $(this).keyup(function () { _IsControlValid(this); }); }
                }
            } catch (ex) { console.log('formbinding find error:', ex); }
        });
        _ClearValidation(formId);
    };
    ///>>>>>>>>>>>>>>>>. formId_summary_errors `${formId}_summary_errors`

    return {    // .validate-form
        Init: function (classOrId) {
            if ((classOrId || "") === "") {
                $(".validate-form").each(function () {
                    let formControl = "#" + $.trim($(this).prop("id") || "");
                    if (formControl.length < 5) { //add and ID to the form
                        formControl = Flash.Guid(7);
                        $(this).prop("id", formControl);
                        formControl = "#" + formControl;
                    }
                    _BindEventToControl(formControl);
                    /*  SwalAlert.Danger("Form ID Required!", "Please give the form an id");
                 Flash.Danger(formControl, "Please give the form an <b>id</b>..");  */
                });
            } else { _BindEventToControl(classOrId); }

            try {
                $('select:not(.normal)').each(function () {
                    $(this).select2({
                        dropdownParent: $(this).parent()
                    });
                });
            } catch (es) { }
        },

        ClearValues: function (form_control) {
            let formId = form_control.replace('#', '');

            try { document.getElementById(formId).reset(); } catch (e) { }
            $(form_control).find("input,textarea,select").not("input[type=button],input[type=reset],input[type=submit],button").each(function () {
                try { $(this).val(""); } catch (e) { }
                try { $(this).prop("value", ""); } catch (e) { }

                try {
                    if (($(this).prop('class') || '').indexOf('select2') >= 0) {
                        try { $(this).select2("val", ""); } catch (xx) { }
                        try { $(this).val("").trigger('change'); } catch (xx) { }
                    }
                } catch (xx) { }
                try {
                    let type = $(this).prop('type') || 'text';
                    if (type == 'checkbox' || type == 'radio') {
                        $(this).prop('checked', false);
                    }
                } catch (xx) { }
            });
            try {
                $(form_control + " .html5uploader").each(function () {
                    let hidControlId = $(this).attr("file-hidden-control");
                    let prevId = $(this).attr("file-preview-div");
                    $("#" + hidControlId).val("");
                    $("#" + prevId).html("");
                });
            } catch (xx) { }
            //$(form_control + ' input:checkbox').each(function () {
            //    $(this).prop('checked', false);
            //});                                              
            //$(form_control + ' input:radio').each(function () {
            //    $(this).prop('checked', false);
            //});  


            _ClearValidation(form_control);
            /* AppForm.ClearValues(divId);
            try { $("select").val(""); } catch (yy) { }
            try { $(".select2").select2("val", ""); } catch (xx) { }

            try {
                $("#formEditRecord .html5uploader").each(function () {
                    let hidControlId = $(this).attr("file-hidden-control");
                    let prevId = $(this).attr("file-preview-div");
                    $("#" + hidControlId).val("");
                    $("#" + prevId).html("");
                });
            } catch (xx) { } */

            Flash.RemoveFlashMessage();
            try { $(`${formId}_summary_errors`).html(""); } catch (e) { }
        },

        IsFormValid: function (form_control, flashDiv) {
            //let formId = form_control.replace('#', '');

            //console.log('IsFormValidd:', form_control, flashDiv);

            Flash.RemoveFlashMessage();
            flashDiv = flashDiv || form_control;
            _ClearValidation(form_control);
            let nError = 0;
            $(form_control).find("input,textarea,select").not(NOT_CONTROL_TYPES).each(function () {
                let obj = _IsControlValid(this);     //isValid, msg
                if (!obj.isValid && nError === 0) {
                    nError += 1;
                    console.log('validation failed:', obj.msg);
                    Flash.Error(form_control, "fill in the required fields...");
                    //if (flashDiv === "") {  Flash.Error(form_control, obj.msg); } else {   Flash.Error(obj.msg, flashDiv);    }
                    $(this).focus();
                }
            });

            if ((nError === 0)) {
                this.ClearValidations(form_control);
                return true;
            }
            return false;
        },

        Reset: function (form_control) { this.ClearValues(form_control); },
        ClearValidations: function (form_control) { _ClearValidation(form_control); },
        DisableSubmitButton: function (form_control, is_enabled) {
            try { $(form_control + " button[type='submit']").attr("disabled", is_enabled); } catch (e) { }
            try { $(form_control + " input[type='submit']").attr("disabled", is_enabled); } catch (e) { }
        },

        GetTemplate: function (control) {
            var tmpCont = $(control).html();
            //return eval("`" + tmpCont + "`");
            return "`" + tmpCont + "`";
            //$("#div-view-lookuptype-details").html(eval(AppForm.GetTemplate("#tmpLookupTypeDetail")));
        },

        GetModel: function (control) {
            var list = JSON.parse(JSON.stringify($(control).find("select, textarea, input").serializeArray()));
            var model = {};
            $.each(list, function (ind, obj) {
                model[obj.name] = obj.value;
                //var cont = $(control + ` [name='${obj.name}']`);
                //var typ = cont.attr("type") || "";  //objtype checkbox
                //if (typ == "checkbox") {
                //    if (obj.value == 'on') {
                //        model[obj.name] = 1;
                //    }
                //    else {
                //        model[obj.name] = 0;
                //    }
                //}
            });

            $(control).find('input:checkbox').each(function () {
                var input = $(this);
                var _name = input.attr('name');

                if (input.is(":checked")) {
                    model[_name] = 1;
                }
                else {
                    model[_name] = 0;
                }
            });

            return model;
        },

        GetQueryString: function (control) {
            var list = JSON.parse(JSON.stringify($(control).find("select, textarea, input").serializeArray()));
            var arrQuery = [];
            $.each(list, function (ind, obj) {
                arrQuery.push(`${obj.name}=${obj.value}`);
            });

            $(control).find('input:checkbox').each(function () {
                var input = $(this);
                var _name = input.attr('name');

                if (input.is(":checked")) {
                    arrQuery.push(`${_name}=1`);
                }
                else {
                    arrQuery.push(`${_name}=0`);
                }
            });

            return arrQuery.join("&");
        },

        SetModel: function (control, model) {
            var list = JSON.parse(JSON.stringify($(control).find("select, textarea, input").serializeArray()));
            console.log('listsetmodel: ', list, 'model:', model);
            //var model = {};
            $.each(list, function (ind, obj) {
                var _valu = model[obj.name] || '';
                var _contr = $(control + ` [name='${obj.name}']`);
                var typ = _contr.attr("type") || "";  //objtype checkbox
                var nameLower = obj.name.toLowerCase();


                if (typ == "date") {
                    try {
                        if (_valu.indexOf('T') > 0)
                            _contr.val(_valu.split('T')[0]);
                    } catch (Ex) { }
                }
                else if (typ == "hidden" && (nameLower.includes("file") || nameLower.includes("doc"))) {
                    _contr.val(_valu);
                    try {
                        //span-ed_FilePhoto  hid_ed_FilePhoto
                        var isImage = GlobalService.IsPicture(_valu);
                        var fileID = obj.name.slice(4);
                        if (isImage) {
                            $(`#span-${fileID}`).html(`<img src='${_valu}' alt="" class="image-preview" />
                                                <i class="fa fa-trash text-danger cursor" title="Remove"
                                                   onclick="$('#${fileID},#hid_${fileID}').val('');$('#span-${fileID}').html('');"></i>`);
                        }
                        else {
                            $(`#span-${fileID}`).html(`<i class="fa fa-trash text-danger cursor" title="Remove"
                                                   onclick="$('#${fileID},#hid_${fileID}').val('');$('#span-${fileID}').html('');"></i>`);
                        }
                    } catch (s) { }
                }
                else {
                    _contr.val(_valu);
                    try { _contr.val(_valu).trigger('change'); } catch (Exx) { }
                }
            });

            $(control).find('input:checkbox').each(function () {
                var input = $(this);
                var _name = input.attr('name');
                var _valu = model[_name] || 0;

                console.log('checkbox:not:::', _name, _valu);
                if (_valu == 1) {
                    input.prop("checked", true);
                }
                else {
                    input.prop("checked", false);
                }
            });


            //$(control).find(':checkbox:not(:checked)').each(
            //    function (index) {
            //        var input = $(this);
            //        var _name = input.attr('name');
            //        var _valu = model[_name] || '';

            //        console.log('checkbox:not:::', _name, _valu);
            //        if (_valu == 1) {
            //            input.attr("checked", "checked");
            //        }
            //        else {
            //            input.removeAttr("checked", "checked");
            //        }
            //    }
            //);
        },

        GetDataTableOptions: function (opts) {
            /* _DTLookupType.DataTable({
                 dom: 'Blfrtip',
                 data: _ListLookupType,
                 columns: LookupService._ColumnsLookupType(),
                 "scrollY": "500px",
                 "scrollCollapse": true,
                 "info": true,
                 lengthMenu: [[10, 25, 50, -1], ['10', '25', '50', ' all']],
                 pagingType: "full_numbers",
                 destroy: true,
                 //responsive: true,
             });*/
            opts = opts || {};
            opts.dom = opts.dom || 'Blfrtip';
            //opts.scrollY = opts.scrollY || "500px";
            //opts.scrollCollapse = opts.scrollCollapse || true;
            opts.info = opts.info || true;
            opts.lengthMenu = opts.lengthMenu || [[10, 25, 50, -1], ['10', '25', '50', ' all']];
            opts.pagingType = opts.pagingType || "full_numbers";
            opts.destroy = opts.destroy || true;
            return opts;
        }

    }
}();

export const SpaAjax = function () {
    function GetTokenValue() {
        try {
            let csrfToken = $('input[name=__RequestVerificationToken]').val() || "";
            //console.log('__RequestVerificationToken', csrfToken);  //XSRF-TOKEN
            return csrfToken;
        } catch (x) { }
        return "";
    }

    return {

        _FxnError: function (error, fxnError) {
            PageLoading.Hide();
            //SwalAlert.Error("SPA Error Occurred...");
            //Flash.Danger("Oops request not successful...");
            //Status:  0  StatusText: error

            if (error.status == 404) {
                alert("Page not Found..");
                PageLoading.Hide();
                // window.location.reload(true);
            }
            else if (error.status == 400) {
                alert("Oooops unable to complete process..");
                PageLoading.Hide();
                // window.location.reload(true);
            }
            else if (error.status == 410 || error.status == 401) {
                PageLoading.Show();
                // window.location.reload(true);
            }
            else if (error.status == 500) {
                SwalAlert.Danger(error.responseJSON.Message);
            }
            //Status:  500  StatusText: error
            else {
                console.log('Status: ', error.status, ' StatusText:', error.statusText);
                if (fxnError != null) {
                    fxnError(error);
                }
            }
        },
        _AddCsrfHeader: function () {
            try {
                let csrfToken = GetTokenValue();
                //console.log('X-CSRF-TOKEN', csrfToken);  //XSRF-TOKEN
                //document.getElementsByName("__RequestVerificationToken").value || "";// document.getElementById('_csrf').value;
                if (csrfToken != "") {
                    $.ajaxSetup({ headers: { 'vantage1d4bfe024e1985498458514c62ce': csrfToken, 'RequestVerificationToken': csrfToken } });
                }
            } catch (x) { }
        },

        InitSubmitForm: function () {
            $(".form-spa").each(function () {
                let formID = "#" + $.trim($(this).prop("id") || "");
                SpaAjax.SubmitForm(formID);
                //try {
                //    this.submit(function (event) {
                //        event.preventDefault();
                //        let form = $(this);
                //        SpaAjax.SubmitForm(form);
                //    });   
                //} catch (e) { }
            });
        },
        SubmitForm: function (formId) {
            let formThis = $(formId); // $.trim($(this).prop("id") || "");

            try {
                formThis.submit(function (event) {
                    event.preventDefault();
                    //-------------- 
                    let form = $(this);
                    let fxnPresubmit = $.trim(form.prop("spa-presubmit") || "");
                    if (fxnPresubmit != "") {
                        //call the function
                        if (fxnPresubmit() != true) {
                            return;
                        }
                    } else {
                        if (!AppForm.IsFormValid(formId)) {
                            return;
                        }
                    }

                    //>>>>>>>>>>>>>>>>>>>>
                    /*  
                        <form id="formId" method="post" action="controll/action" class="form-spa" spa-presubmit="fxnPresubmit" spa-success="fxnSuccess" spa-error="fxnError"></form> 
                    */
                    //Check PreSubmit

                    let fxnSuccess = form.attr('spa-success');
                    let fxnError = form.attr('spa-error');
                    let fxnMethod = form.attr('method').toLowerCase();
                    let urlAction = form.attr('action');
                    let formParams = form.serialize();

                    PageLoading.Show();

                    if (fxnMethod == "get") {
                        this.Get(urlAction, formParams, fxnSuccess, fxnError);
                    } else {
                        this.Post(urlAction, formParams, fxnSuccess, fxnError);
                    }

                    //$.ajax({
                    //    type: form.attr('method'), //"POST",  
                    //    url: form.attr('action'),
                    //    data: form.serialize()
                    //}).done(function (response) {
                    //    SpaAjax._FxnSuccess(response, fxnSuccess);
                    //}).fail(function (error) {
                    //    SpaAjax._FxnError(error, fxnError);
                    //});
                });
            } catch (e) { }
        },

        _getCsrfHeaderObject: function () {
            let csrfToken = $('input[name=__RequestVerificationToken]').val() || "";
            if (csrfToken == "") {
                return { "Content-Type": "application/json; charset=UTF-8" };
            }

            return {
                "Content-Type": "application/json; charset=UTF-8",
                "RequestVerificationToken": csrfToken
            };
        },


        Get: function (url, model, fxnSuccess, fxnError, isLoading = true) {

            if (isLoading == true || isLoading == 1) { PageLoading.Show(); }


            SpaAjax._AddCsrfHeader();
            $.get(url, model, function (response) {
                if (isLoading == true || isLoading == 1) { PageLoading.Hide(); }

                if (fxnSuccess != null) {
                    fxnSuccess(response); //call function 
                }
            }).fail(function (error) {
                SpaAjax._FxnError(error, fxnError); Logger.Danger(err, url);
            });
            //return;


            //fetch(url, {
            //    method: 'GET',
            //    body: JSON.stringify(model),
            //    headers: this._getCsrfHeaderObject()
            //})
            //    .then(res => res.json())
            //    .then(data => {
            //        if (isLoading == true || isLoading == 1) { PageLoading.Hide(); }

            //        if (fxnSuccess != null) {
            //            fxnSuccess(data); //call function 
            //        }
            //    })
            //    .catch((err) => { SpaAjax._FxnError(err, fxnError); Logger.Danger(err, url); });

            /* --OR--
            SpaAjax._AddCsrfHeader();
            $.get(url, params, function (response) {  SpaAjax._FxnSuccess(response, fxnSuccess);
            }).fail(function (error) {  SpaAjax._FxnError(error, fxnError); }); */
        },

        Post: function (url, model, fxnSuccess, fxnError, isLoading = true) {

            if (isLoading == true || isLoading == 1) { PageLoading.Show(); }

            SpaAjax._AddCsrfHeader();
            $.post(url, model, function (response) {
                if (isLoading == true || isLoading == 1) { PageLoading.Hide(); }

                if (response.IsSuccess == false) {
                    SwalAlert.Danger(response.Message);
                }
                if (fxnSuccess != null) {
                    fxnSuccess(response); //call function 
                }
                return false;

            }).fail(function (error) {
                SpaAjax._FxnError(error, fxnError);
            });

            return false;
        },

        PostFile: function (url, formModel, fxnSuccess, fxnError, isLoading = true) {

            if (isLoading == true || isLoading == 1) { PageLoading.Show(); }

            SpaAjax._AddCsrfHeader();

            var formData = new FormData(); //create form
            //var files = document.getElementById("fFileLogo").files;
            //formData.append("FileLogoRaw", files[0]);
            //formData.append("model", JSON.stringify(model));

            Object.keys(formModel).forEach(function (key) {
                if (key == "model") {
                    formData.append(key, JSON.stringify(formModel[key]));
                }
                else {
                    formData.append(key, formModel[key]);
                }
            });

            $.ajax(
                {
                    url: url,
                    data: formData, // send it as formData
                    processData: false,
                    contentType: false,
                    type: "POST", //type is post as we will need to post files
                    success: function (response) {
                        if (isLoading == true || isLoading == 1) { PageLoading.Hide(); }

                        if (response.IsSuccess == false) {
                            SwalAlert.Danger(response.Message);
                        }
                        if (fxnSuccess != null) {
                            fxnSuccess(response); //call function 
                        }
                        return false;
                    },
                    error: function (request, status, error) {
                        //alert(request.responseText);
                        SpaAjax._FxnError(error, fxnError);
                    }
                }
            );

            return false;
        },

        Put: function (url, model, fxnSuccess, fxnError, isLoading = true) {

            if (isLoading == true || isLoading == 1) { PageLoading.Show(); }

            SpaAjax._AddCsrfHeader();
            $.ajax({
                type: "PUT",
                url: url,
                contentType: "application/json",
                data: JSON.stringify(model),
                success: function (response) {
                    if (isLoading == true || isLoading == 1) { PageLoading.Hide(); }

                    if (response.IsSuccess == false) {
                        SwalAlert.Danger(response.Message);
                    }
                    if (fxnSuccess != null) {
                        fxnSuccess(response); //call function 
                    }
                },
                error: function (request, msg, error) {
                    SpaAjax._FxnError(error, fxnError);
                }
            });
        },

        //GetBasicDropdowns: function (code, fxnResponse) {
        //    SpaAjax.Post("/AppGlobalfuncs/GetBasicDropdowns", { code: code }, function (result) { fxnResponse(result); });
        //},

        //GetCountryStates: function (fxnResponse) {
        //    SpaAjax.Post("/AppGlobalfuncs/GetCountries", {}, function (result) { fxnResponse(result); });
        //},

        //SaveTempPathFromByte: function (model, fxnReponse) {
        //    //model.filename = GlobalService.GetGuid() + ".jpg"; model.base64String = "";
        //    SpaAjax.Post('/Uploader/SaveTempPathFromByte', model, function (result) { fxnReponse(result); });
        //},
        //GetBase64FromPath: function (filenamepath, fxnResponse) {
        //    SpaAjax.Post('/Uploader/GetBase64FromPath', { filename: filenamepath }, function (result) { fxnResponse(result); });
        //}


        /*  $.ajax({
                type: "POST",  url: url,
                headers: {  "CSRF-TOKEN-MOONGLADE-FORM": $('input[name="CSRF-TOKEN-MOONGLADE-FORM"]').val()    },
                data: makeCSRFExtendedData(pData),   success: function (data) {   funcSuccess(data);   },   dataType: "json"
            }); 
     
            $.ajax({
                type: "POST",  url: "/Demo?handler=Send",
                beforeSend: function (xhr) {  xhr.setRequestHeader("XSRF-TOKEN", $('input:hidden[name="__RequestVerificationToken"]').val());    },
                data: JSON.stringify({ Item1: item1, Item2: item2, Item3: item3 }),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (response) {  },
                failure: function (response) {   alert(response);   }
            });
        */
    };
}();

export const SpaForm = function () {

    let _ToYMD = function (dmy) {
        dmy = (dmy || "");
        if (dmy == '') return '';

        if (dmy == 'today') {
            return new Date();
        }
        //check if its year
        if (dmy.length == 4) {
            return new Date(dmy, 0, 1);
        }

        let splitXter = '-';
        if (dmy.indexOf('/') > 0) {
            splitXter = '/';
        } else if (dmy.indexOf('.') > 0) {
            splitXter = '.';
        }

        let arrDMY = dmy.split(splitXter);    //dd-MM-yyyy
        let yr = arrDMY[2];
        let mth = arrDMY[1];
        let day = arrDMY[0];

        let yrMthDay = yr + '-' + mth + '-' + day;
        return new Date(yrMthDay);
    };

    let _JDatePicker = function (control, isMonthTimepicker) {   //Standard Format: dd/MM/yyyy  isMonth:1, isMonth:2=>datetimepicker
        //check if it has >>> date-max, date-min, date-default, year-min, year-max
        let maxDate = _ToYMD($(control).attr("date-max"));      //today
        if (maxDate == '') { maxDate = _ToYMD($(control).attr("year-max")); }

        let minDate = _ToYMD($(control).attr("date-min"));
        if (minDate == '') { minDate = _ToYMD($(control).attr("year-min")); }

        let defaultDate = _ToYMD($(control).attr("date-default"));
        //  Date(2099, 11, 31)    min: Date(default: Date(1900, 0, 1)) 
        let kuiOptions = { depth: "year", format: 'dd-MM-yyyy', max: new Date() };
        if (isMonthTimepicker == 1) {           //jdatepicker
            kuiOptions.format = 'MMMM yyyy';
        } else if (isMonthTimepicker == 2) {
            kuiOptions.format = 'dd-MM-yyyy hh:mm:ss';
        }
        if (maxDate != "") { kuiOptions.max = maxDate; }
        if (minDate != "") { kuiOptions.min = minDate; }
        if (defaultDate != "") { kuiOptions.value = defaultDate; }
        // disableDates: [new Date(2015,9,12), new Date(2015,9,22)]

        //check if it has start and end range
        //jdate-endcontrol 

        if (isMonthTimepicker != 2)
            $(control).kendoDatePicker(kuiOptions);
        else
            $(control).kendoDateTimePicker(kuiOptions);

        //let endDateControl = $(control).attr("jdate-endcontrol") || "";
        //if (endDateControl != "") {
        //    try {
        //        let dtStart, dtEnd;
        //        kuiOptions.change = _JDateStartChange(control, endDateControl);
        //        dtStart = $(control).kendoDatePicker(kuiOptions).data("kendoDatePicker");
        //        kuiOptions.change = _JDateEndChange(control, endDateControl);
        //        dtEnd = $(endDateControl).kendoDatePicker(kuiOptions).data("kendoDatePicker");
        //        dtStart.max(dtEnd.value());
        //        dtEnd.min(dtStart.value());
        //    } catch (xx) { console.log('Error endDateControl:', xx); }
        //}


        //console.log('KendoOptions:', kuiOptions);
        /* function startChange() {
                var startDate = start.value(),
                endDate = end.value();

                if (startDate) {
                    startDate = new Date(startDate);
                    startDate.setDate(startDate.GetDate());
                    end.min(startDate);
                } else if (endDate) {
                    start.max(new Date(endDate));
                } else {
                    endDate = new Date();
                    start.max(endDate);
                    end.min(endDate);
                }
            }

            function endChange() {
                var endDate = end.value(),
                startDate = start.value();

                if (endDate) {
                    endDate = new Date(endDate);
                    endDate.setDate(endDate.GetDate());
                    start.max(endDate);
                } else if (startDate) {
                    end.min(new Date(startDate));
                } else {
                    endDate = new Date();
                    start.max(endDate);
                    end.min(endDate);
                }
            }

            var start = $("#start").kendoDatePicker({  change: startChange  }).data("kendoDatePicker");
            var end = $("#end").kendoDatePicker({ change: endChange   }).data("kendoDatePicker");

            start.max(end.value());
            end.min(start.value());
 */


    };

    let _JDateStartChange = function (startControl, endControl) {
        let start = $(startControl).kendoDatePicker().data("kendoDatePicker"),
            end = $(endControl).kendoDatePicker().data("kendoDatePicker");
        var startDate = start.value(),
            endDate = end.value();

        //console.log('_JDateStartChange', startDate, endDate);
        if (startDate) {
            startDate = new Date(startDate);
            startDate.setDate(startDate.GetDate());
            end.min(startDate);
        } else if (endDate) {
            start.max(new Date(endDate));
        } else {
            endDate = new Date();
            start.max(endDate);
            end.min(endDate);
        }
    }

    let _JDateEndChange = function (startControl, endControl) {
        let start = $(startControl).kendoDatePicker().data("kendoDatePicker"),
            end = $(endControl).kendoDatePicker().data("kendoDatePicker");
        var endDate = end.value(),
            startDate = start.value();

        //console.log('_JDateEndChange', startDate, endDate);
        if (endDate) {
            endDate = new Date(endDate);
            endDate.setDate(endDate.GetDate());
            start.max(endDate);
        } else if (startDate) {
            end.min(new Date(startDate));
        } else {
            endDate = new Date();
            start.max(endDate);
            end.min(endDate);
        }
    }

    var IsLockFileupload = false;
    let _Html5Fileupload = function (fileUploadId) {
        try {
            var control_file_id = "#" + fileUploadId;
            $(control_file_id).on('change', function (e) {
                var files = e.target.files;
                if (files.length > 0) {
                    if (window.FormData !== undefined) {
                        var data = new FormData();
                        for (var x = 0; x < files.length; x++) { data.append("file" + x, files[x]); }
                        //----------------- 
                        /* 
            <input type="file" id="file1" name="file1"  style="display: none;"
                file-hidden-control="" file-preview-div="file1-preview" files-allowed="image,pdf,any" 
                file-max-size="9" file-image-max-height="100"  file-show-image="no" />
                         *
                         * <div class="row">
                                <label class="btn btn-default btn-file btn-xs bordered-blue">
                                    <i class="fa fa-cloud-upload"></i> Browse
                                    <input type="file" id="inputBulkLookupGroup" class="html5uploader" style="display: none;"
                                           file-hidden-control="bulkLookupGroup" file-preview-div="previewBulkLookupGroup"
                                           files-allowed="any" file-max-size="1500" file-callback="vuePage.GetUploadedItems()" 
                                           file-dir="filetemp"
                                    />
                                    <input type="hidden" name="bulkLookupGroup" id="bulkLookupGroup" />
                                </label>
                                <span id="previewBulkLookupGroup"> </span>
                            </div>
                         * */
                        var control_hidden_id = "#" + ($(control_file_id).attr("file-hidden-control") || "");
                        var control_loadingPercent_id = ($(control_file_id).attr("file-preview-div") || "") + "_percent";
                        var control_loading_id = "#" + ($(control_file_id).attr("file-preview-div") || "");
                        var file_types = $(control_file_id).attr("files-allowed") || "any"; //image,csv,excel,pdf,zip,any
                        var file_max_size = parseInt(($(control_file_id).attr("file-max-size") || 0)); //in kb
                        var file_max_height = parseInt(($(control_file_id).attr("file-max-height") || 0)); //in px
                        var file_max_width = parseInt(($(control_file_id).attr("file-max-width") || 0)); //in px
                        var file_name = $(control_file_id).val();
                        var file_extension = file_name.split(".").pop().toLowerCase();
                        var file_show_image = $(control_file_id).attr("file-show-image") || "yes"; //yes,no
                        var callback = $(control_file_id).attr("file-callback") || "";
                        var file_dir = $(control_file_id).attr("file-dir") || "filetemp";

                        $(control_hidden_id).val('');

                        //------File Type Validation -----
                        file_types = (file_types.indexOf('image') >= 0) ? ("jpg,png,gif,ico,bmp,jpeg,jfif," + file_types) : file_types;
                        file_types = (file_types.indexOf('excel') >= 0) ? ("xlsx,xls," + file_types) : file_types;
                        var arr_file_types = file_types.toLowerCase().split(',');

                        if (!(jQuery.inArray('any', arr_file_types) !== -1) &&
                            !(jQuery.inArray(file_extension, arr_file_types) !== -1)) {
                            //console.log('File Extension:', file_extension);
                            // console.log('File Types Allowed', arr_file_types);
                            $(control_loading_id).html(`<i><code>Allowed File Types: ${file_types} </code></i>`); return;
                        }

                        //--------Get the FileSize
                        var fileSize = 0; try { fileSize = $(control_file_id)[0].files[0].size; } catch (e) { }
                        if ((fileSize || 0) === 0) { try { fileSize = $(control_file_id)[0].files[0].fileSize; } catch (e) { } }
                        if ((fileSize || 0) > 0) fileSize = (fileSize / 1024.0).toFixed(); // Alert.info('FileSize:' + (fileSize / 1024.0) + 'KB');
                        if (file_max_size > 0 && fileSize > file_max_size) {
                            $(control_loading_id).html(`<i><code>Max. FileSize allowed: ${file_max_size}KB <br /> FileSize uploaded: ${fileSize}KB</code></i>`);
                            return;
                        }

                        if (IsLockFileupload) {
                            return;
                        }

                        //--------------------
                        $(control_loading_id).html(LOADING + " <span id='" + control_loadingPercent_id + "'></span>");
                        IsLockFileupload = true;
                        $.ajax({
                            type: "POST", url: ('/Uploader/Index?id=' + fileUploadId + '&filedir=' + file_dir),
                            contentType: false, processData: false, data: data,
                            success: function (resp) {
                                IsLockFileupload = false;

                                $(control_loading_id).html('');
                                if (resp.IsSuccess) {
                                    $(control_hidden_id).val(resp.TempName);
                                    var btn_delete = `<i id="${control_hidden_id}_deleteicon" class="cursor fa fa-trash-o text-danger" title="Click to remove" onclick="$('${control_hidden_id},${control_file_id}').val('');$('${control_loading_id}').html('');"></i>`;

                                    if (GlobalService.IsPicture(file_name)) {
                                        if (file_show_image == "no") {
                                            $(control_loading_id).html(`<i><a href='/${file_dir}/${resp.TempName}' target='_blank' title='Click to view'>${resp.Name}</a></i> &nbsp; ${btn_delete}`);
                                        } else {
                                            var imgstyle = (file_max_height > 0) ? `max-height:${file_max_height}px;` : "";
                                            if (file_max_width > 0) {
                                                imgstyle = `${imgstyle}max-width:${file_max_width}px;`;
                                            }

                                            if (file_max_height > 10 || file_max_width > 10) {
                                                $(control_loading_id).html(`<img style='${imgstyle}' src='/${file_dir}/${resp.TempName}' /> &nbsp; ${btn_delete}`);
                                            } else {
                                                $(control_loading_id).html(`<img src='/${file_dir}/${resp.TempName}' /> &nbsp; ${btn_delete}`);
                                            }
                                        }
                                    } else if (GlobalService.IsExcelCsv(file_name)) {
                                        $(control_loading_id).html(`<a class='fa fa-file-excel-o'></a> <i> <a href='/${file_dir}/${resp.TempName}' target='_blank' title='Click to download'>${resp.Name}</a></i>  &nbsp; ${btn_delete}`);
                                    } else if (GlobalService.IsPdf(file_name)) {
                                        $(control_loading_id).html(`<a class='fa fa-file-pdf-o'></a> <i><a href='/${file_dir}/${resp.TempName}' target='_blank' title='Click to view'>${resp.Name}</a></i> &nbsp; ${btn_delete}`);
                                    } else {
                                        $(control_loading_id).html(`<i><a href='/${file_dir}/${resp.TempName}' target='_blank' title='Click to download'>${resp.Name}</a></i> &nbsp; ${btn_delete}`);
                                    }
                                    try {
                                        //callback(resp);
                                        if (callback != "") {
                                            window[callback](resp);
                                        }
                                    } catch (e) { console.log('callback error::', callback, e); }
                                    //try { file_callback(resp); } catch (e) {
                                    //    console.log('file_callback(resp);, error::', file_callback, e);
                                    //}
                                } else {
                                    $(control_loading_id).html("<code>Files allowed: " + resp.Message + "</code>");
                                }
                            },
                            error: function (xhr, status, p3, p4) {
                                IsLockFileupload = false;
                                try {
                                    console.log("Error Uploading File: ", xhr, status, p3, p4);
                                    //var err = "Error " + " " + status + " " + p3 + " " + p4;
                                    //if (xhr.responseText && xhr.responseText[0] === "{") err = JSON.parse(xhr.responseText).Message;
                                    $(control_loading_id).html('could not complete file upload, please try again...');
                                } catch (ex) { }
                            },
                            xhr: function () {
                                var xhr = new window.XMLHttpRequest();

                                xhr.upload.addEventListener("progress", function (evt) {
                                    if (evt.lengthComputable) {
                                        var percentComplete = evt.loaded / evt.total;
                                        percentComplete = parseInt(percentComplete * 100);

                                        $("#" + control_loadingPercent_id).html('' + percentComplete + '%');

                                        if (percentComplete === 100) {
                                            $("#" + control_loadingPercent_id).html('please wait...');
                                        }

                                    }
                                }, false);

                                return xhr;
                            }
                        });
                    } else {
                        IsLockFileupload = false;
                        alert("This browser doesn't support HTML5 file uploads!");
                    }
                }
            });

        } catch (xz) { }

        /* <label class="btn btn-default btn-file btn-xs bordered-blue">
            <a class="fa fa-cloud-upload"></a> Browse
        <input type="file" id="file1" name="file1"  style="display: none;"
            file-hidden-control="hidFileUpload" file-preview-div="previewFileupload"
            files-allowed="image,pdf,any" file-max-size="9" />
            <input type="hidden" id="hidFileUpload" value=""/>
        </label><span id="previewFileupload"></span>  html5fileupload('file1', callback);
        */
    };

    return {
        Init: function () {
            SpaAjax.InitSubmitForm();

            SpaForm.LoadFileupload();
            SpaForm.LoadDataTable();
            //SpaForm.LoadDateControl();
            SpaForm.LoadDropdown();

            $("#select2").select2();
            $('select.select2:not(.normal)').each(function () {
                $(this).select2({
                    dropdownParent: $(this).parent().parent()
                });
            });

        },

        ExcelDownload: function (control, filename) {
            filename = filename || $('head title').text();
            $(control).table2excel({
                name: filename,
                filename: filename,
                fileext: ".xls",
                preserveColors: false,
                exclude: ".no_export,.ts_checkbox,.ts_checkbox_all,.btn,.td-actions",
                exclude_img: true,
                exclude_links: true,
                exclude_inputs: true
            });
        },


        LoadDataTable: function (datatableControl) {
            //try { scutum.plugins.dataTables.init(datatableControl);   } catch (x) { }
            try {
                //$(datatableControl).DataTable({
                //    "pagingType": "full_numbers",
                //    "lengthMenu": [
                //        [10, 25, 50, -1],
                //        [10, 25, 50, "All"]
                //    ],
                //    responsive: true,
                //    language: {
                //        search: "_INPUT_",
                //        searchPlaceholder: "filter records",
                //    }
                //});

                $(datatableControl).DataTable({
                    "destroy": true,
                    "pagingType": "full_numbers",
                    "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
                    responsive: true,
                    language: { search: "_INPUT_", searchPlaceholder: "filter records" },
                    //--DataTableExport
                    dom: 'lBfrtip',
                    buttons: ['excel', 'pdf', 'print'] //'csv','pageLength', 'copy',
                });
                /*   opts = opts || {};
            opts.dom = opts.dom || 'Blfrtip';
            //opts.scrollY = opts.scrollY || "500px";
            //opts.scrollCollapse = opts.scrollCollapse || true;
            opts.info = opts.info || true;
            opts.lengthMenu = opts.lengthMenu || [[10, 25, 50, -1], ['10', '25', '50', ' all']];
            opts.pagingType = opts.pagingType || "full_numbers";
            opts.destroy = opts.destroy || true;
            return opts; */
            } catch (e) { }
        },
        VueDataTable: function (datatableControl, vueApp) {
            //$.noConflict();  jQuery.noConflict();
            try { let dt = $(datatableControl).DataTable(); dt.destroy(); } catch (e) { }
            vueApp.$nextTick(function () { SpaForm.LoadDataTable(datatableControl); });
        },
        LoadDateControl: function (control) {
            control = (control || "");
            if (control == "" || control.indexOf('.') >= 0) {
                $('.jdatepicker').each(function () { _JDatePicker(this, 0); });
                $('.jmonthpicker').each(function () { _JDatePicker(this, 1); });
                $('.jdatetimepicker').each(function () { _JDatePicker(this, 2); });
            }
            else {
                if ($(control).hasClass("jdatepicker")) {
                    _JDatePicker(control, 0);
                } else if ($(control).hasClass("jmonthpicker")) {
                    _JDatePicker(control, 1);
                } else if ($(control).hasClass("jdatetimepicker")) {
                    _JDatePicker(control, 2);
                }
            }
        },
        LoadDropdown: function (control) {
            control = (control || "");

            try {
                try { $.fn.select2.defaults.set('width', '100%'); } catch (c) { }

                if (control == "") {
                    console.log('select nocontrol drop0down: ');
                    $('.select2').each(function () {
                        let placeholder = $(this).attr("placeholder") || ($(this).attr("title") || "Select an option");
                        let selectId = $(this).attr("id");
                        let isMultiple = $(this).attr("multiple") || "";
                        let isCloseOnSelect = isMultiple == "" ? true : false;
                        //let multiple = $(this).attr("multiple") || "";
                        try { $(control).select2("destroy"); } catch (ex) { }

                        $('#' + selectId).select2({
                            //    tags: "true",
                            placeholder: placeholder,
                            closeOnSelect: isCloseOnSelect,
                            allowClear: true
                        })
                            .on('select2:open', function (e) {
                                $('.select2-dropdown').addClass("uk-animation-slide-top-small");
                            });

                    });
                }
                else {
                    console.log('select drop0down specific: ', control);
                    let placeholder = $(control).attr("placeholder") || ($(control).attr("title") || "Select an option");

                    try { $(control).select2("destroy"); } catch (ex) { }
                    let isMultiple = $(control).attr("multiple") || "";
                    let isCloseOnSelect = isMultiple == "" ? true : false;

                    $(control).select2({
                        placeholder: placeholder,  // tags: "true",
                        closeOnSelect: isCloseOnSelect,
                    }).on('select2:open', function (e) {
                        $('.select2-dropdown').addClass("uk-animation-slide-top-small");
                    });
                    console.log('loading select 2:', isMultiple);
                }


            } catch (xx) { console.log('err select2:', xx); }
            //try {
            //    control = (control || "");
            //    if (control == "") {
            //        $('.select2').each(function () {
            //            let placeholder = $(this).attr("placeholder") || ($(this).attr("title") || "Select an option");
            //            //let multiple = $(this).attr("multiple") || "";
            //            try { $(control).select2("destroy"); } catch (ex) { }
            //            $(this).select2({
            //                tags: "true",
            //                placeholder: placeholder,
            //                allowClear: true,
            //                // theme: 'bootstrap4'
            //            });

            //        });
            //    }
            //    else {
            //        let placeholder = $(control).attr("placeholder") || ($(control).attr("title") || "Select an option");
            //        try { $(control).select2("destroy"); } catch (ex) { }
            //        $(control).select2({
            //            tags: "true",
            //            placeholder: placeholder,
            //            allowClear: true,
            //            //theme: 'bootstrap4'
            //        });
            //    }
            //} catch (x) { } 
        },

        LoadFileupload: function () {
            $('.html5uploader').each(function () {
                var id = $(this).attr("id") || "";
                //var callBackfxn = $(this).attr("file-callback");
                try {
                    _Html5Fileupload(id);   //html5fileupload(id, callBackfxn);
                } catch (e) { }
            });
        },

        ToogleMDCheckbox: function (grpClass) {
            alert('GroupClass' + grpClass);
            //console.log('CheckBox:', event.srcElement.id);
            let isCheck = false;
            if (isCheck) {
                $('input:checkbox' + grpClass).prop("checked", true);
            } else {
                $('input:checkbox' + grpClass).prop("checked", false);
            }
            //$(".ts_checkbox_all").iCheck({
            //    checkboxClass: "icheckbox_md", radioClass: "iradio_md", increaseArea: "20%"
            //}).on("ifChecked", function () {
            //    dataTableControl.find(".ts_checkbox").prop("checked", !0).iCheck("update").closest("tr").addClass("row_highlighted");
            //}).on("ifUnchecked", function () {
            //    dataTableControl.find(".ts_checkbox").prop("checked", !1).iCheck("update").closest("tr").removeClass("row_highlighted");
            //});
        },
        ToggleChecks: function (control) {
            let checkboxClass = $(control).attr("group");
            let isChecked = $(control).is(":checked");

            if (isChecked) {
                $('input:checkbox.' + checkboxClass).prop("checked", true);
            } else {
                $('input:checkbox.' + checkboxClass).prop("checked", false);
            }
        },

        CheckAll: function (control) {
            //checkbox-group-class='controlClass'
            let checkboxClass = $(control).attr("checkbox-group-class") || control;

            let value = $(control).html();
            let isCheck = value.indexOf(' Select All') > 0;

            if (isCheck) {
                $('input:checkbox.' + checkboxClass).prop("checked", true);
                $(control).html('<i class="fa fa-square text-danger"></i> Uncheck All');
            } else {
                $('input:checkbox.' + checkboxClass).prop("checked", false);
                $(control).html('<i class="fa fa-check-square text-success"></i> Select All');
            }
        },
        UnCheckAll: function (control) {
            let checkboxClass = $(control).attr("checkbox-group-class");
            $('input:checkbox.' + checkboxClass).prop("checked", false);
            $(control).html('<i class="fa fa-check-square text-success"></i> Select All');
        },
        GetCheckedIDs: function (controlClass) {
            let CountChecked = $('input[type="checkbox"]:checked' + controlClass).length;
            //console.log('CountChecked:', CountChecked);
            if (CountChecked == 0) {
                //SwalAlert.Info("Please Check at least a record...");
                return [];
            }

            var Ids = [];
            $.each($('input[type="checkbox"]:checked' + controlClass), function () {
                Ids.push($(this).val());
            });
            return Ids;
        },

        //--EDITORS
        TinyMCE: function (control) {
            try {
                tinymce.init({
                    selector: control, // "#tiny_textarea",
                    height: 80,
                    plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste"],
                    toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                    relative_urls: !1, remove_script_host: !1,
                    images_upload_url: "/Uploader/TinyMceUpload"
                });
            } catch (e) { }
        },

        Summernote: function (control) {

            let mode = $(control).attr("summer-mode") || ""; //summer-mode="popup"
            let placeholder = $(control).attr("placeholder") || ""; //placeholder="popup"

            if (mode == "popup") {
                $(control).summernote({
                    popover: {
                        air: [
                            ['color', ['color']],
                            ['font', ['bold', 'underline', 'clear']]
                        ]
                    }
                });
            } else {
                $(control).summernote({
                    placeholder: placeholder,
                    tabsize: 2,
                    height: 100, // height: ($(window).height() - 300),
                    toolbar: [
                        ['style', ['bold', 'italic', 'underline', 'clear']],
                        ['font', ['strikethrough', 'superscript', 'subscript']],
                        ['fontsize', ['fontsize']],
                        ['color', ['color']],
                        ['para', ['ul', 'ol', 'paragraph']],
                        ['table', ['table']],    // ['height', ['height']]
                        ['insert', ['link', 'picture', 'video']],
                        ['view', ['fullscreen', 'codeview', 'help']]
                    ],
                    onImageUpload: function (files, editor, welEditable) {
                        //sendFile(files[0], editor, welEditable);
                        data = new FormData();
                        data.append("file", files[0]);
                        $.ajax({
                            data: data,
                            type: "POST",
                            url: "/Uploader/Summernoteupload",
                            cache: false,
                            contentType: false,
                            processData: false,
                            success: function (path) {
                                console.log('image path:', path);
                                editor.insertImage(welEditable, path);
                            }
                        });
                    }
                });

            }

        }
    }
}();

export const HighChartService = function () {
    var getArrayFloat = function (startIndex, stringArray) {
        var arr = new Array();
        for (var k = startIndex; k < stringArray.length; k++) {
            var valu = $.trim(stringArray[k]); if (valu.length > 0) { try { arr.push(parseFloat(valu)); } catch (e) { } }
        }
        return arr;
    };

    return {
        HighchartLine: function (divId, title, subTitle, categoriesXaxis, yAxisTitle, graphData, showLabels) {
            title = title || '';// 'Monthly Average Temperature';
            subTitle = subTitle || '';// 'Source: WorldClimate.com';
            /* categoriesXaxis = categoriesXaxis || "Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec";
             yAxisTitle = yAxisTitle || 'Temperature (=N=)';
             graphData = graphData || "Port Harcourt, 7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6; " +
                 "Lagos, 3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8; " +
                 "Abuja, 3.9, 4.5, 5.7, 8.5, 11.9, 7.2, 9.0, 16.6, 4.2, 10.3, 16.6, 4.8";
             */
            showLabels = showLabels || true;

            var seriesData = graphData.split(';');
            var nameData = [];
            for (var k = 0; k < seriesData.length; k++) {
                var row = $.trim(seriesData[k]); if (row.length < 2) continue;
                var rowValus = row.split(',');
                nameData.push({ name: $.trim(rowValus[0]), data: getArrayFloat(1, rowValus) });
            }
            $('#' + divId).highcharts({
                chart: { type: 'line' }, credits: false, title: { text: title }, subtitle: { text: subTitle },
                xAxis: { categories: categoriesXaxis.split(',') }, yAxis: { title: { text: yAxisTitle } },
                tooltip: { crosshairs: true, shared: true },
                plotOptions: { line: { dataLabels: { enabled: showLabels }, enableMouseTracking: true } },
                series: nameData
            });
            //jQuery.noConflict();
            $('#' + divId).resize();
        },
        HighchartBarchart: function (divId, title, subTitle, categoriesXaxis, yAxisTitle, graphData) {
            /* title = title || 'Monthly Average Temperature';
             subTitle = subTitle || 'Source: WorldClimate.com';
             categoriesXaxis = categoriesXaxis || "Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec,,";
             yAxisTitle = yAxisTitle || 'Temperature (=N=)';
             graphData = graphData || "Port Harcourt, 7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6; " +
                 "Lagos, 3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8; " +
                 "Abuja, 3.9, 4.5, 5.7, 8.5, 11.9, 7.2, 9.0, 16.6, 4.2, 10.3, 16.6, 4.8";
               */
            var seriesData = graphData.split(';');
            var nameData = [];
            for (var k = 0; k < seriesData.length; k++) {
                var row = $.trim(seriesData[k]); if (row.length < 2) continue;
                var rowValus = row.split(',');
                nameData.push({ name: $.trim(rowValus[0]), data: getArrayFloat(1, rowValus) });
            }

            $('#' + divId).highcharts({
                chart: { type: 'column' }, credits: false,
                title: { text: title }, subtitle: { text: subTitle },
                xAxis: { categories: categoriesXaxis.split(','), crosshair: true },
                yAxis: { min: 0, title: { text: yAxisTitle } },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td> <td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
                    footerFormat: '</table>', shared: true, useHTML: true
                },
                plotOptions: { column: { pointPadding: 0.2, borderWidth: 0 } },
                series: nameData
            });
            $('#' + divId).resize();
        },
        HighchartBarVertical: function (divId, title, subTitle, categoriesXaxis, yAxisTitle, graphData, inMillions) {
            /* title = title || 'Historic World Population by Region';
             subTitle = subTitle || 'Source: <a href="https://en.wikipedia.org/wiki/World_population">Wikipedia.org</a>';
             categoriesXaxis = categoriesXaxis || "Africa,America,Asia,Europe,Oceania";
             yAxisTitle = yAxisTitle || 'Population (millions)';
             graphData = graphData || "Year 1800, 107, 31, 635, 203, 2;  Year 1900, 133, 156, 947, 408, 6;  Year 2012, 1052, 954, 4250, 740, 38";
             inMillions = inMillions || ' millions';
              */
            var seriesData = graphData.split(';');
            var nameData = [];
            for (var k = 0; k < seriesData.length; k++) {
                var row = $.trim(seriesData[k]); if (row.length < 2) continue;
                var rowValus = row.split(',');
                nameData.push({ name: $.trim(rowValus[0]), data: getArrayFloat(1, rowValus) });
            }

            //$('#' + divId).highcharts({
            //    chart: { type: 'column' }, credits: false,
            //    title: { text: title }, subtitle: { text: subTitle },
            //    xAxis: { categories: categoriesXaxis.split(','), crosshair: true },
            //    yAxis: { min: 0, title: { text: yAxisTitle } },
            //    tooltip: {
            //        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            //        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td> <td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
            //        footerFormat: '</table>', shared: true, useHTML: true
            //    },
            //    plotOptions: { column: { pointPadding: 0.2, borderWidth: 0 } },
            //    series: nameData
            //});

            $('#' + divId).highcharts({
                chart: { type: 'bar' }, title: { text: title }, subtitle: { text: subTitle },
                xAxis: { categories: categoriesXaxis.split(','), title: { text: null }, crosshair: true },
                yAxis: { min: 0, title: { text: yAxisTitle, align: 'high' }, labels: { overflow: 'justify' } },
                tooltip: { valueSuffix: inMillions }, credits: false,
                plotOptions: { bar: { dataLabels: { enabled: true } } },
                legend: {
                    layout: 'vertical', align: 'right', verticalAlign: 'top', x: -40, y: 80, floating: true, borderWidth: 1,
                    backgroundColor: ((Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'), shadow: true
                },
                series: nameData
            });
            $('#' + divId).resize();
        },
        HighchartFromTable: function (divId, tableId, title, yAxisTitle, allowDecimals) {
            // https://code.highcharts.com/modules/data.js 
            //title = title || 'Data extracted from a HTML table in the page';
            //yAxisTitle = yAxisTitle || 'Units';
            allowDecimals = allowDecimals || true;

            $('#' + divId).highcharts({
                data: { table: tableId }, chart: { type: 'column' }, title: { text: title }, credits: false,
                yAxis: { allowDecimals: allowDecimals, title: { text: yAxisTitle } },
                tooltip: { formatter: function () { return '<b>' + this.series.name + '</b><br/>' + this.point.y + ' ' + this.point.name.toLowerCase(); } }
            });
            $('#' + divId).resize();
        },
        HighchartPie: function (divId, title, graphData, seriesName) {
            //title = title || 'Browser market shares January, 2015 to May, 2015';
            seriesName = seriesName || '';// 'Brands';
            //graphData = graphData || "Port Harcourt, 27.0; Lagos, 13.9; Abuja, 16.6";

            var seriesData = graphData.split(';');
            var nameY = [];
            for (var k = 0; k < seriesData.length; k++) {
                var row = $.trim(seriesData[k]); if (row.length < 2) continue;
                var rowValus = row.split(',');
                var amount = 0; try { amount = parseFloat($.trim(rowValus[1])); } catch (e) { }
                if (k === 1)
                    nameY.push({ name: $.trim(rowValus[0]), y: amount, sliced: true, selected: true });
                else nameY.push({ name: $.trim(rowValus[0]), y: amount });
            }

            //===============
            $('#' + divId).highcharts({
                chart: { plotBackgroundColor: null, plotBorderWidth: null, plotShadow: false, type: 'pie' }, credits: false,
                title: { text: title }, tooltip: { pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>' },
                plotOptions: {
                    pie: {
                        allowPointSelect: true, cursor: 'pointer',
                        dataLabels: {
                            enabled: true, format: '<b>{point.name}</b>: {point.y:.1f} ({point.percentage:.1f})',
                            style: { color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black' }
                        }, showInLegend: true
                    }
                },
                series: [{ name: seriesName, colorByPoint: true, data: nameY }]
            });
            $('#' + divId).resize();
        },
        /* //jQuery.noConflict();    jQuery('#' + containerId).highcharts({ */
    };
}();
  
  
export const GlobalService = function () {
    return {
        IsName: function (valu) { return /^([a-zA-Z\s\."-]+){3,}$/.test(valu); },
        IsEmail: function (valu) { return /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/.test(valu); },
        IsURL: function (valu) {
            if (valu.indexOf("http://") < 0 || valu.indexOf("https://") < 0 || valu.indexOf("ftp://") < 0) valu = "http://" + valu;
            return /^(http(?:s)?\:\/\/[a-zA-Z0-9\-]+(?:\.[a-zA-Z0-9\-]+)*\.[a-zA-Z]{2,6}(?:\/?|(?:\/[\w\-]+)*)(?:\/?|\/\w+\.[a-zA-Z]{2,4}(?:\?[\w]+\=[\w\-]+)?)?(?:\&[\w]+\=[\w\-]+)*)$/.test(valu);
        },
        IsDate: function (valu) { return /^((19|20)[0-9][0-9])[- //.](0[1-9]|1[012])[- //.](0[1-9]|[12][0-9]|3[01])/.test(valu); },//(yyyy-mm-dd)
        IsDateTime: function (valu) { return /^((19|20)[0-9][0-9])[- //.](0[1-9]|1[012])[- //.](0[1-9]|[12][0-9]|3[01])/.test(valu); },//(yyyy-mm-dd)
        IsPhone: function (valu) { return /^(0\d{10})?(,(\s)?0\d{10})*$/.test(valu); },
        IsPassword: function (valu, min) { min = min || 0; if (min === 0 && valu.length >= 4) return true; else return (valu.length >= min); },
        IsPasswordStrong: function (valu, min) {
            min = min || 8;
            if (min === 8) return /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[\W]).*$/.test(valu);
            else return ("/^(?=.{" + min + ",})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[\W]).*$/").test(valu);
        },

        IsInArray: function (arr, item) { return (jQuery.inArray(item, arr) !== -1); },
        GetFileExtension: function (filename) {
            if ((filename || "") == "") return "";
             return filename.split(".").pop().toLowerCase(); 
            },
        IsPicture: function (filename) {
            if ((filename || "") == "") return false;
            return this.IsInArray(["jpg", "png", "gif", "ico", "bmp", "jpeg", "jfif"], this.GetFileExtension(filename));
        },
        IsExcel: function (filename) { return this.IsInArray(["xls", "xlsx"], this.GetFileExtension(filename)); },
        IsCsv: function (filename) { return this.IsInArray(["csv"], this.GetFileExtension(filename)); },
        IsExcelCsv: function (filename) { return this.IsInArray(["xls", "xlsx", "csv"], this.GetFileExtension(filename)); },
        IsPdf: function (filename) { return this.IsInArray(["pdf"], this.GetFileExtension(filename)); },
        IsZip: function (filename) { return this.IsInArray(["zip", "rar"], this.GetFileExtension(filename)); },
        IsPicturePdf: function (filename) { return this.IsInArray(["jpg", "png", "gif", "ico", "bmp", "jpeg", "jfif", "pdf"], this.GetFileExtension(filename)); },

        removeSpecialXters: function (str) {
            var newStr = str.replace("/", "_"); newStr = newStr.replace("-", "_"); newStr = newStr.replace("#", "");
            newStr = newStr.replace(" ", ""); newStr = newStr.replace("?", ""); newStr = newStr.replace(";", "");
            newStr = newStr.replace(",", ""); newStr = newStr.replace("\"", "");
            newStr = newStr.replace("<", ""); newStr = newStr.replace(">", ""); newStr = newStr.replace("%", "");
            newStr = newStr.replace("*", ""); newStr = newStr.replace("@", "");
            newStr = newStr.replace("+", ""); newStr = newStr.replace("#", ""); newStr = newStr.replace("~", "");
            return newStr;
        },

        CleanupUserID: function (input) {
            let valu = $(input).val();
            valu = valu.replace(/ /g, '');     // valu = valu.replace(/@/g, '');
            valu = valu.replace(/[^0-9A-Za-z_.]+/gi, "");
            $(input).val(valu);
        },
        GetGuid: function () {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        RandomDigits: function (numb) {
            var add = 1, max = 12 - add;   // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.   

            if (numb > max) {
                return this.RandomDigits(max) + this.RandomDigits(numb - max);
            }

            max = Math.pow(10, numb + add);
            var min = max / 10; // Math.pow(10, n) basically
            var number = Math.floor(Math.random() * (max - min + 1)) + min;

            return ("" + number).substring(add);
        },
        ToMoney: function (num) {
            num = num || 0;
            try {
                let amount = parseFloat(num);
                let p = amount.toFixed(2).split(".");
                let chars = p[0].split("").reverse();
                let newstr = '';
                let count = 0;
                for (x in chars) {
                    count++;
                    if (count % 3 == 1 && count != 1) {
                        newstr = chars[x] + ',' + newstr;
                    } else {
                        newstr = chars[x] + newstr;
                    }
                }

                let money = newstr + "." + p[1];
                //console.log('NUM:', num, " MOney: ", money);
                return money;
            } catch (ex) { }

            return num;
        },
        WatermarkToMoney: function (control, hiddenControl) {
            let amount = 0;
            let strAmt = $.trim($(control).val()).replace(/\,/g, '');
            if (isNaN(strAmt)) {
                $(hiddenControl).val('');
                $(control).val('');
                $(control).focus();
            } else {
                amount = ToMoney(strAmt);
                $(control).val(amount);
                $(hiddenControl).val(strAmt);
            }
        },

        GetUniqueArray: function (jsonAll) {
            var arr = [], result = [];
            $.each(jsonAll, function (index, value) {
                if ($.inArray(value.id, arr) === -1) { arr.push(value.id); result.push(value); }
            }); return result;
        },

        ParseJsonDateObject: function (jsonDate) {
            try {
                jsonDate = $.trim(jsonDate || "");
                if (jsonDate === "undefined" || jsonDate === "") return "";
                var dt = jsonDate.replace("/Date(", ""); dt = dt.replace(")/", "");
                return new Date(parseInt(dt));
            } catch (ex) { }
            return "";
        },
        GetMonthName: function (month) {
            var mths = ',Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec'.split(',');
            return mths[month];
        },
        ParseJsonDate: function (jsonDate) {
            var ddate = this.ParseJsonDateObject(jsonDate); if (ddate === "") return "";
            var mths = new Array(); mths = 'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec'.split(',');
            return (mths[ddate.getMonth()] + " " + ddate.GetDate() + ", " + ddate.getFullYear());
        },
        ParseJsonDateTime: function (jsonDate) {
            var ddate = this.ParseJsonDateObject(jsonDate); if (ddate === "") return "";
            var mths = new Array(); mths = 'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec'.split(',');
            return (mths[ddate.getMonth()] + " " + ddate.GetDate() + ", " + ddate.getYear() + ". " + ddate.getHours() + ":" + ddate.getMinutes());
        },

        GetDate: function (apiDate) { //2016-09-21T00:00:00  "2016-09-28T11:43:58.683"
            if ((apiDate || "") === "") return "";
            apiDate = apiDate.substring(0, 10); return apiDate;
        },
        GetDateTime: function (apiDate) { //2016-09-21T00:00:00  "2016-09-28T11:43:58.683"
            if ((apiDate || "") === "") return "";
            apiDate = apiDate.substring(0, 19); return apiDate.replace("T", " ");
        },

        GetBadge: function (text, type) {
            text = text || "";
            if (type === 'success') return '<span class="badge badge-success badge-square"><i class="fa fa-check-circle-o"></i> ' + text + '</span>';
            else if (type === 'warning') return '<span class="badge badge-warning badge-square"><i class="fa fa-spinner"></i> ' + text + '</span>';
            else if (type === 'error') return '<span class="badge badge-danger badge-square"><i class="fa fa-spinner"></i> ' + text + '</span>';
            else return '<span class="badge badge-' + type + ' badge-square"><i class="fa fa-spinner"></i> ' + text + '</span>';
        },

        CheckAll: function (className, isChecked) {
            try { try { $('.' + className).prop('checked', isChecked); } catch (e) { } this.CountCheck(className); } catch (ee) { }
        },
        CountCheck: function (className) {
            try { $('#div' + className).html(''); } catch (e) { }
            var n = 0;
            try { n = $('.' + className + ':checked').length; } catch (e) { }
            try { $('#div' + className).html('<b>selected:</b> ' + n); } catch (e) { }
        },
        ToHtmlCase: function (str) {
            let html = str.replace(/([A-Z])/g, ' $1').trim();//.replace(/^./, function (st) { return st.toUpperCase(); });
            html = html.replace('Lkp ', '').replace(' Id', '').replace('Fk ', '').replace('Sys ', '');
            return html;
        },

        IsIpAddressValid: function (ipAddress) {
            ipAddress = (ipAddress || "");
            if (ipAddress == null || ipAddress == "") {
                console.log('ipAddress:', ipAddress, 'valid: false');
                return false;
            }

            //var ip = '/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/';
            var ip = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/igm;
            var result = ip.test(ipAddress);
            //var result = ipAddress.match(ip);
            console.log('ipAddressv4/6:', ipAddress, 'result:', result);
            //if (ipAddress.match(ip) != null) {  
            //    return true;
            //}
            //console.log('ipAddress:', ipAddress, 'valid: false');
            return result;
        },
        IsIpAddressV4: function (ipAddress) {
            ipAddress = (ipAddress || "");
            if (ipAddress == null || ipAddress == "")
                return false;

            var ip = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gm;
            var result = ip.test(ipAddress);
            console.log('ipAddress4:', ipAddress, 'result:', result);
            return result;
        },
        IsIpAddressV6: function (ipAddress) {
            //if (ipAddress.length > 5) return true;
            /* Match IPv4 address */
            /* Match IPv6 address */
            /* Match both IPv4, IPv6 addresses */

            var ip = /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/igm;
            var result = ip.test(ipAddress);
            console.log('ipAddress6:', ipAddress, 'result:', result);
            return result;
        },
        Copy: function (text) {
            /* Select the text field */
            //copyText.select();
            //copyText.setSelectionRange(0, 99999); /* For mobile devices */

            /* Copy the text inside the text field */
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand("copy");
            document.body.removeChild(textarea);

            SwalAlert.Success("Copied!");
        },

        ShowTab: function (tabId, tabClass) {
            $(tabClass + "-btn").removeClass("active");
            $(tabClass).hide(100);
            $(tabId).show(100);
            $(tabId + "-btn").addClass("active");
        }
        /* **********  HIDE/SHOW WITH ICONS ***************** */
        //function enableControl(id) { try { $("#" + id).prop('disabled', false); } catch (e) { } } 
        //function showDiv(id) { $('#' + id).fadeIn();  try { $('#btn' + id).html('<a class="fa fa-chevron-circle-down"></a> hide'); } catch (e) { } }
        //function hideDiv(id) { $('#' + id).fadeOut(); try { $('#btn' + id).html('<a class="fa fa-chevron-circle-up"></a> show'); } catch (e) { } }
        //function hideShowDiv(div) { try { if (document.getElementById(div).style.display == 'none') showDiv(div); else hideDiv(div); } catch (e) { } }
        //function showHide(id) { $('#' + id).fadeToggle(); } 
        //function showHideIcon(divId) {
        //    var div = document.getElementById(divId);
        //    if (div.style.display == 'block') { div.style.display = 'none'; $('#icon' + divId).html('<i class="fa fa-caret-square-o-right"></i>');
        //    } else { div.style.display = 'block'; $('#icon' + divId).html('<i class="fa fa-caret-square-o-down"></i>'); }
        //}   
    };
}();

//#region ________ Validator Section
export const GenericHelper = function () {
    return {
        // --type: url|number|email|text|date|datetime|password|password_strong|alpha|alphanumeric
        IsURL: function (valu) {
            if (valu.indexOf("http://") < 0 && valu.indexOf("https://") < 0 && valu.indexOf("ftp://") < 0)
                valu = "http://" + valu;
            return /^(http(?:s)?\:\/\/[a-zA-Z0-9\-]+(?:\.[a-zA-Z0-9\-]+)*\.[a-zA-Z]{2,6}(?:\/?|(?:\/[\w\-]+)*)(?:\/?|\/\w+\.[a-zA-Z]{2,4}(?:\?[\w]+\=[\w\-]+)?)?(?:\&[\w]+\=[\w\-]+)*)$/.test(valu);
        },
        IsDigits: function (valu) { return /^\d+$/.test(valu); /* /^[0-9]+$/.test(valu); */ },
        IsNumeric: function (valu) { return /^\d*\.\d+$/.test(valu); },
        IsDigitsOrDecimals: function (valu) { return this.IsDigits(valu) || this.IsNumeric(valu); },
        IsAlpha: function (valu) { return /^[a-zA-Z ]*$/.test(valu); },
        IsAlphaNumeric: function (valu) { return /^[a-zA-Z0-9 ]*$/.test(valu); },
        IsEmail: function (valu) { return /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/.test(valu); },

        IsDate: function (valu) { return /^((19|20)[0-9][0-9])[- //.](0[1-9]|1[012])[- //.](0[1-9]|[12][0-9]|3[01])/.test(valu); },//(yyyy-mm-dd)
        IsDateTime: function (valu) { return /^((19|20)[0-9][0-9])[- //.](0[1-9]|1[012])[- //.](0[1-9]|[12][0-9]|3[01])/.test(valu); },//(yyyy-mm-dd)
        IsPhone: function (valu) { return /^(0\d{10})?(,(\s)?0\d{10})*$/.test(valu); },
        IsPasswordComplex: function (valu, min) {
            min = min || 8;
            if (min === 8) return /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[\W]).*$/.test(valu);
            else return ("/^(?=.{" + min + ",})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[\W]).*$/").test(valu);
        },

        IsInArray: function (arr, item) { return (jQuery.inArray(item, arr) !== -1); },
        GetFileExtension: function (filename) { 
             if ((filename || "") == "") return "";
             return filename.split(".").pop().toLowerCase();
        },
        IsPicture: function (filename) { return this.IsInArray(["jpg", "png", "gif", "ico", "bmp", "jpeg", "jfif"], this.GetFileExtension(filename)); },
        IsExcel: function (filename) { return this.IsInArray(["xls", "xlsx"], this.GetFileExtension(filename)); },
        IsCsv: function (filename) { return this.IsInArray(["csv"], this.GetFileExtension(filename)); },
        IsExcelCsv: function (filename) { return this.IsInArray(["xls", "xlsx", "csv"], this.GetFileExtension(filename)); },
        IsPdf: function (filename) { return this.IsInArray(["pdf"], this.GetFileExtension(filename)); },
        IsZip: function (filename) { return this.IsInArray(["zip", "rar"], this.GetFileExtension(filename)); },
        IsPicturePdf: function (filename) { return this.IsInArray(["jpg", "png", "gif", "ico", "bmp", "jpeg", "jfif", "pdf"], this.GetFileExtension(filename)); },

        RemoveSpecialXters: function (str) { return str.replace(/[^a-z0-9_ ]/gi, ''); },

        CleanupUserID: function (input) {
            let valu = $.trim($(input).val());
            valu = valu.replace(/ /g, '');     // valu = valu.replace(/@/g, '');
            valu = valu.replace(/[^0-9A-Za-z_.]+/gi, "");
            $(input).val(valu);
        },
        GetGuid: function () {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        RandomDigits: function (numb) {
            var add = 1, max = 12 - add;   // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.

            if (numb > max) {
                return this.RandomDigits(max) + this.RandomDigits(numb - max);
            }

            max = Math.pow(10, numb + add);
            var min = max / 10; // Math.pow(10, n) basically
            var number = Math.floor(Math.random() * (max - min + 1)) + min;

            return ("" + number).substring(add);
        },
        ToMoney: function (num) {
            num = num || 0;
            try {
                let amount = parseFloat(num);
                let p = amount.toFixed(2).split(".");
                let chars = p[0].split("").reverse();
                let newstr = '';
                let count = 0;
                for (x in chars) {
                    count++;
                    if (count % 3 == 1 && count != 1) {
                        newstr = chars[x] + ',' + newstr;
                    } else {
                        newstr = chars[x] + newstr;
                    }
                }

                let money = newstr + "." + p[1];
                //console.log('NUM:', num, " MOney: ", money);
                return money;
            } catch (ex) { }

            return num;
        },
        WatermarkToMoney: function (control, hiddenControl) {
            let amount = 0;
            let strAmt = $.trim($(control).val()).replace(/\,/g, '');
            if (isNaN(strAmt)) {
                $(hiddenControl).val('');
                $(control).val('');
                $(control).focus();
            } else {
                amount = ToMoney(strAmt);
                $(control).val(amount);
                $(hiddenControl).val(strAmt);
            }
        },

        GetUniqueArray: function (jsonAll) {
            var arr = [], result = [];
            $.each(jsonAll, function (index, value) {
                if ($.inArray(value.id, arr) === -1) { arr.push(value.id); result.push(value); }
            }); return result;
        },

        ParseJsonDateObject: function (jsonDate) {
            try {
                jsonDate = $.trim(jsonDate || "");
                if (jsonDate === "undefined" || jsonDate === "") return "";
                var dt = jsonDate.replace("/Date(", ""); dt = dt.replace(")/", "");
                return new Date(parseInt(dt));
            } catch (ex) { }
            return "";
        },
        GetMonthName: function (month) {
            var mths = ',Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec'.split(',');
            return mths[month];
        },
        ParseJsonDate: function (jsonDate) {
            var ddate = this.ParseJsonDateObject(jsonDate); if (ddate === "") return "";
            var mths = new Array(); mths = 'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec'.split(',');
            return (mths[ddate.getMonth()] + " " + ddate.GetDate() + ", " + ddate.getFullYear());
        },
        ParseJsonDateTime: function (jsonDate) {
            var ddate = this.ParseJsonDateObject(jsonDate); if (ddate === "") return "";
            var mths = new Array(); mths = 'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec'.split(',');
            return (mths[ddate.getMonth()] + " " + ddate.GetDate() + ", " + ddate.getYear() + ". " + ddate.getHours() + ":" + ddate.getMinutes());
        },

        GetDate: function (apiDate) { //2016-09-21T00:00:00  "2016-09-28T11:43:58.683"
            if ((apiDate || "") === "") return "";
            apiDate = apiDate.substring(0, 10); return apiDate;
        },
        GetDateTime: function (apiDate) { //2016-09-21T00:00:00  "2016-09-28T11:43:58.683"
            if ((apiDate || "") === "") return "";
            apiDate = apiDate.substring(0, 19); return apiDate.replace("T", " ");
        },

        GetBadge: function (text, type) {
            text = text || "";
            if (type === 'success') return '<span class="badge badge-success badge-square"><i class="fa fa-check-circle-o"></i> ' + text + '</span>';
            else if (type === 'warning') return '<span class="badge badge-warning badge-square"><i class="fa fa-spinner"></i> ' + text + '</span>';
            else if (type === 'error') return '<span class="badge badge-danger badge-square"><i class="fa fa-spinner"></i> ' + text + '</span>';
            else return '<span class="badge badge-' + type + ' badge-square"><i class="fa fa-spinner"></i> ' + text + '</span>';
        },

        CheckAll: function (className, isChecked) {
            try { try { $('.' + className).prop('checked', isChecked); } catch (e) { } this.CountCheck(className); } catch (ee) { }
        },
        CountCheck: function (className) {
            try { $('#div' + className).html(''); } catch (e) { }
            var n = 0;
            try { n = $('.' + className + ':checked').length; } catch (e) { }
            try { $('#div' + className).html('<b>selected:</b> ' + n); } catch (e) { }
        },
        ToHtmlCase: function (str) {
            let html = str.replace(/([A-Z])/g, ' $1').trim();//.replace(/^./, function (st) { return st.toUpperCase(); });
            html = html.replace('Lkp ', '').replace(' Id', '').replace('Fk ', '').replace('Sys ', '');
            return html;
        },

        IsIpAddressValid: function (ipAddress) {
            ipAddress = (ipAddress || "");
            if (ipAddress == null || ipAddress == "") {
                console.log('ipAddress:', ipAddress, 'valid: false');
                return false;
            }

            //var ip = '/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/';
            var ip = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/igm;
            var result = ip.test(ipAddress);
            //var result = ipAddress.match(ip);
            console.log('ipAddressv4/6:', ipAddress, 'result:', result);
            //if (ipAddress.match(ip) != null) {
            //    return true;
            //}
            //console.log('ipAddress:', ipAddress, 'valid: false');
            return result;
        },
        IsIpAddressV4: function (ipAddress) {
            ipAddress = (ipAddress || "");
            if (ipAddress == null || ipAddress == "")
                return false;

            var ip = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gm;
            var result = ip.test(ipAddress);
            console.log('ipAddress4:', ipAddress, 'result:', result);
            return result;
        },
        IsIpAddressV6: function (ipAddress) {
            //if (ipAddress.length > 5) return true;
            /* Match IPv4 address */
            /* Match IPv6 address */
            /* Match both IPv4, IPv6 addresses */

            var ip = /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/igm;
            var result = ip.test(ipAddress);
            console.log('ipAddress6:', ipAddress, 'result:', result);
            return result;
        },
        Copy: function (text) {
            /* Select the text field */
            //copyText.select();
            //copyText.setSelectionRange(0, 99999); /* For mobile devices */

            /* Copy the text inside the text field */
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand("copy");
            document.body.removeChild(textarea);

            SwalAlert.Success("Copied!");
        },

    };
}();


export const JsValidate = function () {

    /*  --type: url|number|email|date|datetime|pwds|password_strong|digit|alpha|alphanumb|phone
jsvalidate="[req,type:number,as:email,
       min:12,max:50,startsWith:xxx,endsWith:zzz,
       minlength:10,maxlength:50,eq:50.34,neq:50.34, gte:90,lte:28,
       range:[20|300],range-date:[2023-01-04|2023-06-01],opts:[John|Peters|Luke]],
       neq-id:#name,eq-id:#name,min-id:#nameid,max-id:#nameid,lte-id:#nameid,gte-id:#nameid,
       min-date:2020-02-29,max-date:2020-02-29,min-date-id:#fromdate,max-date-id:#todate" */

    const NOT_BUTTONS = "input[type=button],input[type=reset],input[type=submit],button";
    const NOT_CONTROL_TYPES = `${NOT_BUTTONS},input[type=file],input[type=checkbox],input[type=radio]`;
    const FIND_INPUTS = "input,textarea,select";

    const fnFeedback = (controlElement, message, errorType) => { //error,success,default
        let span_id = ((($(controlElement).prop("id") || "") == "") ? ($(controlElement).prop("name") || "") : $(controlElement).prop("id")).toLowerCase().replace(".", "_") + "_valmsg";
        try { $("#" + span_id).remove(); } catch (ex) { }

        $(controlElement).removeClass("is-invalid");
        $(controlElement).removeClass("is-valid");

        if (errorType == "error" || errorType == "danger") { //error
            $(controlElement).after(`<span id="${span_id}" class="invalid-feedback"></span>`);
            $(controlElement).addClass("is-invalid");
            // Flash.Error(control, error_message);
        }
        else if (errorType == "success") { //success
            $(controlElement).after(`<span id="${span_id}" class="valid-feedback"></span>`);
            $(controlElement).addClass("is-valid");
        }

        $("#" + span_id).html(message);
        $("#" + span_id).attr("title", message);
    };

    const fnGetFormRules_xxx = (formId) => {
        var model = {
            id: formId,
            fields: [
                /* {
                     type: "",
                     name: "",
                     labelElement: null,
                     inputElement: null,
                     isRequired: true,
                     as: "",
                     rules: [

                     ]
                 } */
            ]
        };

        const excludeInputs = ["reset", "button", "submit"];

        $(formId + ' :input').each(function () {
            const inputType = ($(this).prop("type") || "").toLowerCase();
            if (excludeInputs.includes(inputType)) return;

            const label = $(this).parent().find('label');
            //if (label == null) return;

            var fieldElement = {
                inputType: inputType,
                name: $(this).prop("name"),
                labelElement: label,
                inputElement: this,
                isRequired: $(this).prop("required"),
                as: label == null ? "" : $(label).html(),
                type: inputType,
                rules: []
            };

            const inputRules = ($(this).attr("jsvalidate") || "").trim().slice(1, -1);
            if (inputRules != "") {

                const arrInputRules = inputRules.split(',');
                for (let i = 0; i < arrInputRules.length; i++) {
                    const keyValu = (arrInputRules[i]).trim();
                    if (keyValu.indexOf(':') <= 0) {
                        if (keyValu.toLowerCase().startsWith("req")) {
                            fieldElement.isRequired = true;
                        }
                    }
                    else {
                        const arrkeyValus = keyValu.split(':');
                        const key = arrkeyValus[0].trim().toLowerCase();
                        const keyItem = arrkeyValus[1].trim();

                        if (key == "as") {
                            fieldElement.as = keyItem;
                        }
                        else if (key == "type") {
                            fieldElement.type = keyItem;
                        }
                        else if (key == "opts") {
                            const _arrKeyValus = (keyItem.slice(1, -1)).trim().split('|');
                            fieldElement.rules.push({ key, value: _arrKeyValus });
                        }
                        else if (key == "range") {
                            const _arrKeyValus = (keyItem).trim().split('-');
                            fieldElement.rules.push({ key, value: _arrKeyValus });
                        }
                        else {

                            fieldElement.rules.push({ key, value: keyItem });
                        }
                    }
                }
            }

            model.fields.push(fieldElement);
        });

        return model;
    };

    const fnBindEvent = function (formId) {
        $(formId).find(FIND_INPUTS).not(NOT_CONTROL_TYPES).each(function () {
            try {
                const inputType = ($(this).prop("type") || "").toLowerCase();
                if (inputType.indexOf("select") >= 0) {
                    $(this).change(function () { fnIsInputValid(this); });
                }
                else {
                    $(this).keyup(function () { fnIsInputValid(this); });
                }
            } catch (ex) { console.log('formbinding find error:', ex); }
        });

        fnClearValidation(formId);
    };

    const fnClearValidation = (formElement) => {
        $(formElement).find(FIND_INPUTS).not(NOT_CONTROL_TYPES).each(function () {
            fnFeedback(this, "", "");
        });

        try {
            $(formElement + " > .js-summernote").each(function () { 
                fnFeedback(this, "", ""); 
            });
        } catch (exx) { }
    };

    const fnIsInputValid = (inputElement) => {
        const inputElementRules = _fnGetInputRules(inputElement);
        if (inputElementRules == null) return false;

        const validationText = _fnValidationText(inputElementRules) || "";

        if (validationText == "") {
            fnFeedback(inputElement, "", "success");
            return true;
        }

        fnFeedback(inputElement, validationText, "error");
        return false;
    };

    const _fxToInt = (valu) => {
        try { return parseInt(valu); } catch (xx) { } return 0;
    }
    const _fxToFloat = (valu) => {
        try { return parseFloat(valu); } catch (xx) { } return 0;
    }

    const _fnValidationText = (obj) => {
        if (obj.isRequired && obj.value == "") return `${obj.as} is required`;
        if (obj.value == "") return "";

        //type: url|number|email|date|datetime|pwds|password_strong|digit|alpha|alphanumb|phone
        if (obj.type.startsWith("numb") && !GenericHelper.IsDigitsOrDecimals(obj.value)) {
            $(obj.inputElement).val("");
            return `${obj.as} requires numeric value???`;
        }
        if (obj.type.startsWith("digit") && !GenericHelper.IsDigits(obj.value)) {
            $(obj.inputElement).val(""); //.prop("value", "");
            return `${obj.as} requires digits value`;
        }
        if (obj.type == "url" && !GenericHelper.IsURL(obj.value)) return `${obj.as} is not valid`;
        if (obj.type == "email" && !GenericHelper.IsEmail(obj.value)) return `${obj.as} is not valid`;
        if (obj.type == "date" && !GenericHelper.IsDate(obj.value)) return `${obj.as} is not valid`;
        if (obj.type == "datetime" && !GenericHelper.IsDateTime(obj.value)) return `${obj.as} is not valid`;
        if ((obj.type == "password_strong" || obj.type.startsWith("pwds")) && !GenericHelper.IsPasswordComplex(obj.value)) return `${obj.as} is not complex enough`;
        if (obj.type == "alpha" && !GenericHelper.IsAlpha(obj.value)) return `${obj.as} is not valid`;
        if (obj.type.startsWith("alphanumb") && !GenericHelper.IsAlphaNumeric(obj.value)) return `${obj.as} is not valid`;
        if (obj.type.startsWith("phone") && !GenericHelper.IsPhone(obj.value)) return `${obj.as} is not valid`;

        /*  --type: url|number|email|date|datetime|pwds|password_strong|digit|alpha|alphanumb|phone
jsvalidate="[req,type:number,as:email,
           min:12,max:50,startsWith:xxx,endsWith:zzz,
           minlength:10,maxlength:50,eq:50.34,neq:50.34, gte:90,lte:28,
           range:[20|300],range-date:[2023-01-04|2023-06-01],opts:[John|Peters|Luke]],
           neq-id:#name,eq-id:#name,min-id:#nameid,max-id:#nameid,lte-id:#nameid,gte-id:#nameid,
           min-date:2020-02-29,max-date:2020-02-29,min-date-id:#fromdate,max-date-id:#todate" */
        const isNumber = obj.type.startsWith("numb") || obj.type.startsWith("digit");
        const objValue = isNumber ? _fxToFloat(obj.value) : obj.value;

        const fx_getdate = (dt) => ((dt.startsWith("today")) ? new Date() : new Date(dt));

        for (var i = 0; i < (obj.rules || []).length; i++) {
            //{ key, value: keyItem }
            const item = obj.rules[i];
            if (item.key == "min" && objValue < _fxToFloat(item.value)) return `${obj.as} minimum allowed is ${item.value}`;
            if (item.key == "max" && objValue > _fxToFloat(item.value)) return `${obj.as} maximum allowed is ${item.value}`;

            if (item.key == "startsWith" && objValue.startsWith(item.value)) return `${obj.as} is not valid`;
            if (item.key == "endsWith" && objValue.endsWith(item.value)) return `${obj.as} is not valid`;

            if (item.key == "minlength" && obj.value.length < _fxToInt(item.value)) return `${obj.as} minimum length is ${item.value}`;
            if (item.key == "maxlength" && obj.value.length > _fxToInt(item.value)) return `${obj.as} maximum length is ${item.value}`;

            if (item.key == "eq" && obj.value != item.value) return `${obj.as} is not valid`;
            if (item.key == "neq" && obj.value == item.value) return `${obj.as} is not valid`;

            if (item.key == "range") {
                if (!(_fxToFloat(item.value[0]) <= objValue && objValue <= _fxToFloat(item.value[1])))
                    return `${obj.as} should be in range of ${item.value[0]} - ${item.value[1]}`;
            }

            if (item.key == "range-date") {
                const objValueDate = fx_getdate(obj.value);
                const minDate = fx_getdate(item.value[0]);
                const maxDate = fx_getdate(item.value[1]);

                if (!(minDate <= objValueDate && objValueDate <= maxDate))
                    return `${obj.as} should be in range of ${minDate} - ${maxDate}`;
            }

            if (item.key == "opts") {
                if (!item.value.includes(obj.value.toLowerCase()))
                    return `${obj.as} invalid options`;
            }

            if (item.key == "min-date" && fx_getdate(obj.value) < fx_getdate(item.value)) {
                return `${obj.as} minimum allowed date is ${item.value}`;
            }
            if (item.key == "max-date" && fx_getdate(obj.value) < fx_getdate(item.value)) {
                return `${obj.as} maximum allowed date is ${item.value}`;
            }

            //-- checks with ids
            if (item.key.endsWith("-id")) {
                const compareValue = $.trim($(item.value).val()).toLowerCase();
                const inputValue = obj.value.toLowerCase();

                if (item.key == "neq-id" && inputValue == compareValue) {
                    return `${obj.as} neq-id not valid`;
                }
                if (item.key == "eq-id" && inputValue != compareValue) {
                    return `${obj.as}`;
                }
                if (item.key == "min-id" && _fxToFloat(inputValue) < _fxToFloat(compareValue)) {
                    return `${obj.as} min-id not valid`;
                }
                if (item.key == "max-id" && _fxToFloat(inputValue) > _fxToFloat(compareValue)) {
                    return `${obj.as} max-id not valid`;
                }

                //dates
                if (item.key == "min-date-id" && fx_getdate(inputValue) < fx_getdate(compareValue)) {
                    return `${obj.as} min-date-id not valid`;
                }
                if (item.key == "max-date-id" && fx_getdate(inputValue) > fx_getdate(compareValue)) {
                    return `${obj.as} max-date-id not valid`;
                }
            }

        }

        return "";
        /*  --type: url|number|email|date|datetime|pwds|password_strong|digit|alpha|alphanumb|phone
jsvalidate="[req,type:number,as:email,
            min:12,max:50,startWith:xxx,endWith:zzz,
            minlength:10,maxlength:50,eq:50.34,neq:50.34,gt:90,lt:28,gte:x,lte:x,
            neq-id:#name,eq-id:#name,min-id:#nameid,max-id:#nameid,lte-id:#nameid,gte-id:#nameid,
            min-date:2020-02-29,max-date:2020-02-29,min-date-id:#fromdate,max-date-id:#todate,
            range:20-300,opts:[John|Peters|Luke]]" */

    };

    const _fnGetInputRules = (inputElement) => {
        const inputType = ($(inputElement).prop("type") || "").toLowerCase();

        const excludeInputs = ["reset", "button", "submit"];
        if (excludeInputs.includes(inputType)) return null;

        const label = $(inputElement).parent().find('label');
        //if (label == null) return;

        const thisElement = $(inputElement);
        var fieldElement = {
            inputType: inputType,
            name: $(inputElement).prop("name"),
            labelElement: label,
            inputElement: thisElement,
            isRequired: $(inputElement).prop("required"),
            as: label == null ? "" : $(label).html(),
            type: inputType,
            value: ($(inputElement).prop("value") || "").trim(),
            rules: []
        };

        const inputRules = ($(inputElement).attr("jsvalidate") || "").trim().slice(1, -1);
        if (inputRules != "") {

            const arrInputRules = inputRules.split(',');
            for (let i = 0; i < arrInputRules.length; i++) {
                const keyValu = (arrInputRules[i]).trim();
                if (keyValu.indexOf(':') <= 0) {
                    if (keyValu.toLowerCase().startsWith("req")) {
                        fieldElement.isRequired = true;
                    }
                }
                else {
                    const arrkeyValus = keyValu.split(':');
                    const key = arrkeyValus[0].trim().toLowerCase();
                    const keyItem = arrkeyValus[1].trim();

                    if (key == "as") {
                        fieldElement.as = keyItem;
                    }
                    else if (key == "type") {
                        fieldElement.type = keyItem;
                    }
                    else if (key == "opts") {
                        const _arrKeyValus = (keyItem.toLowerCase().slice(1, -1)).trim().split('|');
                        fieldElement.rules.push({ key, value: _arrKeyValus });
                    }
                    else if (key == "range") {
                        const _arrKeyValus = (keyItem).trim().split('-');
                        fieldElement.rules.push({ key, value: _arrKeyValus });
                    }
                    else {

                        fieldElement.rules.push({ key, value: keyItem });
                    }
                }
            }


        }

        return fieldElement;
    };

    const _setInputValue = (element, value) => {
        try { $(element).val(value); } catch (e) { }
        try { $(element).prop("value", value); } catch (e) { }
    };

    const _setSelect2Value = (element, value) => {
        try { $(element).select2("val", value); } catch (xx) { }
        try { $(element).val(value).trigger('change'); } catch (xx) { }
    };

    return {
        Init: function (classOrId) {
            if ((classOrId || "").trim() == "") { classOrId = ".jsvalidate"; }

            const firstChar = classOrId.trim().charAt(0); // .,#
            if (firstChar != "." && firstChar != "#") {
                firstChar = "#";
                classOrId = `#${classOrId}`;
            }

            if (firstChar == ".") { // by Class
                $(classOrId).each(function () {
                    let formId = $.trim($(this).prop("id") || "");
                    if (formId == "") { //add and ID to the form
                        formId = GenericHelper.GetGuid();
                        $(this).prop("id", formId);
                    }
                    fnBindEvent(`#${formId}`);
                });
            }
            else if (firstChar == "#") { //by Id
                fnBindEvent(classOrId);
            }
        },

        Reset: function (formId) {
            try { document.getElementById(formId.slice(1)).reset(); } catch (e) { }
            try {
                //$(formId + " > .jsvalidate-clear").each(function () {
                $(".jsvalidate-clear").each(function () {
                    try {
                        $(this).html("");
                        $(this).val("");
                    } catch (xx) { }
                });
            } catch (xx) { }
            try { $(".js-summernote").summernote('code', ""); } catch (esx) { }


            $(formId).find(FIND_INPUTS).not(NOT_BUTTONS).each(function () {
                _setInputValue(this, "");

                if (($(this).prop('class') || '').indexOf('select2') >= 0) {
                    _setSelect2Value(this, "");
                }

                try {
                    let type = $(this).prop('type') || 'text';
                    if (type == 'checkbox' || type == 'radio') {
                        $(this).prop('checked', false);
                    }
                } catch (xx) { }
            });

            fnClearValidation(formId);
            //$(form_control + " .html5uploader").each(function () {
            //    let hidControlId = $(this).attr("file-hidden-control");
            //    let prevId = $(this).attr("file-preview-div");
            //    $("#" + hidControlId).val("");
            //    $("#" + prevId).html("");
            //});
            //Flash.RemoveFlashMessage();
        },

        IsFormValid: function (formId, flashDiv) {
            //Flash.RemoveFlashMessage();
            flashDiv = flashDiv || formId;
            fnClearValidation(formId);

            let nError = 0;
            $(formId).find(FIND_INPUTS).not(NOT_CONTROL_TYPES).each(function () {
                const isValid = fnIsInputValid(this);
                if (!isValid && nError === 0) {
                    nError += 1;
                    //Flash.Error(formId, "fill in the required fields...");
                    $(this).focus();
                }
            });

            try {
                $(formId + " > .js-summernote").each(function () {
                    var controlID = $(this).prop("id");
                    var isRequired = $(this).attr("required") || "";
                    var value = $(`#${controlID}`).summernote('code') || "";

                    if (isRequired == "required" && value == "") {
                        //fnFeedback = (controlElement, message, errorType)
                        fnFeedback(this, "Please enter record is required", "error");
                    }
                    
                });
            } catch (exx) { }

            if ((nError === 0)) {
                fnClearValidation(formId);
                return true;
            }

            return false;
        },

        ClearValidations: function (formId) {
            fnClearValidation(formId);
        },
        DisableSubmitButton: function (formId, is_enabled) {
            try { $(formId + " button[type='submit']").attr("disabled", is_enabled); } catch (e) { }
            try { $(formId + " input[type='submit']").attr("disabled", is_enabled); } catch (e) { }
        },
        GetModel: function (formId) {
            var list = JSON.parse(JSON.stringify($(formId).find(FIND_INPUTS).serializeArray()));
            var model = {};
            $.each(list, function (ind, obj) {
                model[obj.name] = obj.value;
            });

            $(formId).find('input:checkbox').each(function () {
                var input = $(this);
                var _name = input.attr('name');
                //var _id = input.attr('id');
                 
                if (_name !== undefined) {
                    model[_name] = input.is(":checked") ? 1 : 0;
                }
            });
             
            try {
                $(formId).find(".js-summernote").each(function () {
                    var _name = $(this).attr('name');
                    var _id = $(this).attr('id');
                    var _value = $(`#${_id}`).summernote('code') || "";
                     
                    model[_name] = _value;
                });
            } catch (exx) { }

            return model;
        },

        GetQueryString: function (formId) {
            var list = JSON.parse(JSON.stringify($(formId).find(FIND_INPUTS).serializeArray()));
            var arrQuery = [];
            $.each(list, function (ind, obj) {
                arrQuery.push(`${obj.name}=${obj.value}`);
            });

            $(formId).find('input:checkbox').each(function () {
                var input = $(this);
                var _name = input.attr('name');

                if (input.is(":checked")) {
                    arrQuery.push(`${_name}=1`);
                }
                else {
                    arrQuery.push(`${_name}=0`);
                }
            });

            return arrQuery.join("&");
        },

        SetModel: function (formId, model) {
            var list = JSON.parse(JSON.stringify($(formId).find(FIND_INPUTS).serializeArray()));
            console.log('listsetmodel: ', list, 'model:', model);
            //var model = {};
            $.each(list, function (ind, obj) {
                var _valu = model[obj.name] || '';
                var _contr = $(formId + ` [name='${obj.name}']`);
                var typ = _contr.attr("type") || "";  //objtype checkbox
                var nameLower = obj.name.toLowerCase();
                var _contrID = _contr.attr("id");

                if (typ == "date") {
                    try {
                        if (_valu.indexOf('T') > 0) _contr.val(_valu.split('T')[0]);
                    } catch (Ex) { }
                }
                else if (typ == "hidden" && (nameLower.includes("file") || nameLower.includes("doc"))) {
                    _contr.val(_valu);
                    try {
                        //span-ed_FilePhoto  hid_ed_FilePhoto
                        var isImage = GlobalService.IsPicture(_valu);
                        var fileID = _contrID.slice(4);
                        if (isImage) {
                            $(`#span-${fileID}`).html(`<img src='${_valu}' alt="" class="image-preview" />
                                                <i class="fa fa-trash text-danger cursor" title="Remove"
                                                   onclick="$('#${fileID},#hid_${fileID}').val('');$('#span-${fileID}').html('');"></i>`);
                        }
                        else {
                            $(`#span-${fileID}`).html(`<i class="fa fa-trash text-danger cursor" title="Remove"
                                                   onclick="$('#${fileID},#hid_${fileID}').val('');$('#span-${fileID}').html('');"></i>`);
                        }
                    } catch (s) { }
                }
                else {
                    _contr.val(_valu);
                    try { _contr.val(_valu).trigger('change'); } catch (Exx) { }
                }
            });

            $(formId).find('input:checkbox').each(function () {
                var input = $(this);
                var _name = input.attr('name');
                var _valu = model[_name] || 0;

                console.log('checkbox:not:::', _name, _valu);
                input.prop("checked", (_valu == 1));
            });


            try {
                $(formId + " > .js-summernote").each(function () {
                    var _name = $(this).attr('name');
                    var _valu = model[_name] || "";
                    $(this).summernote('code', _valu);
                });
            } catch (exx) { }

            //$(control).find(':checkbox:not(:checked)').each(
            //    function (index) {
            //        var input = $(this);
            //        var _name = input.attr('name');
            //        var _valu = model[_name] || '';

            //        console.log('checkbox:not:::', _name, _valu);
            //        if (_valu == 1) {
            //            input.attr("checked", "checked");
            //        }
            //        else {
            //            input.removeAttr("checked", "checked");
            //        }
            //    }
            //);
        }
    }
}();

//#endregion

export const TogglePassword = () => {
    // <i class="bi bi-eye-slash toggle-pwd" pwd-id="txtPassword"></i>
    $(".toggle-pwd").each(function () {
        let _this = $(this);
        let _inputId = $(this).attr("pwd-id") || "";

        const passwordInput = document.querySelector(_inputId);
        $(this).click(function () {
            // toggle the type attribute
            const type = passwordInput.getAttribute("type") === "password" ? "text" : "password";
            passwordInput.setAttribute("type", type);

            // toggle the icon
            this.classList.toggle("fa-eye");
        });

    });
};

$(document).ready(function () {
    //if (location.protocol == 'http:') { location.href = location.href.replace(/^http:/, 'https:'); }
    //$('img').on("error", function () { $(this).attr('src', '/assets/img/logo.svg'); });
    //if (location.protocol != 'https:') {
    //    location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
    //}

    //change .close to .btn-close
    //fxnLoadPage(1000); 
});

export function fxnLoadPage(timeInSecs) {
    _PreventRightclick(); //Prevent Right-Click

    TogglePassword();

    //loadCountryStateLgasJson();

    setTimeout(function () {
        //AppForm.Init(); //ValidateForm=> .validate-form

        //SpaForm.Init();
        JsValidate.Init(); //Init
        fnInitSelect();
        fnInitFileUpload();
        fnInitSummernote();
        fnInitDatePicker();
        LoadCountrySelectDropdown.Init();

        PageLoading.Hide();

        setTimeout(function () {
            $(".flash-message").fadeOut();
            $(".flash-message").remove();
        }, 7000);

        console.log("loading page");
    }, timeInSecs);
    
}

export function fnInitSelect() {
    try {
        //'select:not(.normal)'
        $('.select2').each(function () {
            $(this).select2({
                dropdownParent: $(this).parent()
            });
        });
    } catch (es) {

    }
}


//this is not in use
export function fnCountryDropdownPopulate_XXX(controlID) { 
    var isoCountries = [
        { id: 'NG', text: 'Nigeria', selected: true },
        { id: 'AF', text: 'Afghanistan' },
        { id: 'AX', text: 'Aland Islands' },
        { id: 'AL', text: 'Albania' },
        { id: 'DZ', text: 'Algeria' },
        { id: 'AS', text: 'American Samoa' },
        { id: 'AD', text: 'Andorra' },
        { id: 'AO', text: 'Angola' },
        { id: 'AI', text: 'Anguilla' },
        { id: 'AQ', text: 'Antarctica' },
        { id: 'AG', text: 'Antigua And Barbuda' },
        { id: 'AR', text: 'Argentina' },
        { id: 'AM', text: 'Armenia' },
        { id: 'AW', text: 'Aruba' },
        { id: 'AU', text: 'Australia' },
        { id: 'AT', text: 'Austria' },
        { id: 'AZ', text: 'Azerbaijan' },
        { id: 'BS', text: 'Bahamas' },
        { id: 'BH', text: 'Bahrain' },
        { id: 'BD', text: 'Bangladesh' },
        { id: 'BB', text: 'Barbados' },
        { id: 'BY', text: 'Belarus' },
        { id: 'BE', text: 'Belgium' },
        { id: 'BZ', text: 'Belize' },
        { id: 'BJ', text: 'Benin' },
        { id: 'BM', text: 'Bermuda' },
        { id: 'BT', text: 'Bhutan' },
        { id: 'BO', text: 'Bolivia' },
        { id: 'BA', text: 'Bosnia And Herzegovina' },
        { id: 'BW', text: 'Botswana' },
        { id: 'BV', text: 'Bouvet Island' },
        { id: 'BR', text: 'Brazil' },
        { id: 'IO', text: 'British Indian Ocean Territory' },
        { id: 'BN', text: 'Brunei Darussalam' },
        { id: 'BG', text: 'Bulgaria' },
        { id: 'BF', text: 'Burkina Faso' },
        { id: 'BI', text: 'Burundi' },
        { id: 'KH', text: 'Cambodia' },
        { id: 'CM', text: 'Cameroon' },
        { id: 'CA', text: 'Canada' },
        { id: 'CV', text: 'Cape Verde' },
        { id: 'KY', text: 'Cayman Islands' },
        { id: 'CF', text: 'Central African Republic' },
        { id: 'TD', text: 'Chad' },
        { id: 'CL', text: 'Chile' },
        { id: 'CN', text: 'China' },
        { id: 'CX', text: 'Christmas Island' },
        { id: 'CC', text: 'Cocos (Keeling) Islands' },
        { id: 'CO', text: 'Colombia' },
        { id: 'KM', text: 'Comoros' },
        { id: 'CG', text: 'Congo' },
        { id: 'CD', text: 'Congo}, Democratic Republic' },
        { id: 'CK', text: 'Cook Islands' },
        { id: 'CR', text: 'Costa Rica' },
        { id: 'CI', text: 'Cote D\'Ivoire' },
        { id: 'HR', text: 'Croatia' },
        { id: 'CU', text: 'Cuba' },
        { id: 'CY', text: 'Cyprus' },
        { id: 'CZ', text: 'Czech Republic' },
        { id: 'DK', text: 'Denmark' },
        { id: 'DJ', text: 'Djibouti' },
        { id: 'DM', text: 'Dominica' },
        { id: 'DO', text: 'Dominican Republic' },
        { id: 'EC', text: 'Ecuador' },
        { id: 'EG', text: 'Egypt' },
        { id: 'SV', text: 'El Salvador' },
        { id: 'GQ', text: 'Equatorial Guinea' },
        { id: 'ER', text: 'Eritrea' },
        { id: 'EE', text: 'Estonia' },
        { id: 'ET', text: 'Ethiopia' },
        { id: 'FK', text: 'Falkland Islands (Malvinas)' },
        { id: 'FO', text: 'Faroe Islands' },
        { id: 'FJ', text: 'Fiji' },
        { id: 'FI', text: 'Finland' },
        { id: 'FR', text: 'France' },
        { id: 'GF', text: 'French Guiana' },
        { id: 'PF', text: 'French Polynesia' },
        { id: 'TF', text: 'French Southern Territories' },
        { id: 'GA', text: 'Gabon' },
        { id: 'GM', text: 'Gambia' },
        { id: 'GE', text: 'Georgia' },
        { id: 'DE', text: 'Germany' },
        { id: 'GH', text: 'Ghana' },
        { id: 'GI', text: 'Gibraltar' },
        { id: 'GR', text: 'Greece' },
        { id: 'GL', text: 'Greenland' },
        { id: 'GD', text: 'Grenada' },
        { id: 'GP', text: 'Guadeloupe' },
        { id: 'GU', text: 'Guam' },
        { id: 'GT', text: 'Guatemala' },
        { id: 'GG', text: 'Guernsey' },
        { id: 'GN', text: 'Guinea' },
        { id: 'GW', text: 'Guinea-Bissau' },
        { id: 'GY', text: 'Guyana' },
        { id: 'HT', text: 'Haiti' },
        { id: 'HM', text: 'Heard Island & Mcdonald Islands' },
        { id: 'VA', text: 'Holy See (Vatican City State)' },
        { id: 'HN', text: 'Honduras' },
        { id: 'HK', text: 'Hong Kong' },
        { id: 'HU', text: 'Hungary' },
        { id: 'IS', text: 'Iceland' },
        { id: 'IN', text: 'India' },
        { id: 'ID', text: 'Indonesia' },
        { id: 'IR', text: 'Iran}, Islamic Republic Of' },
        { id: 'IQ', text: 'Iraq' },
        { id: 'IE', text: 'Ireland' },
        { id: 'IM', text: 'Isle Of Man' },
        { id: 'IL', text: 'Israel' },
        { id: 'IT', text: 'Italy' },
        { id: 'JM', text: 'Jamaica' },
        { id: 'JP', text: 'Japan' },
        { id: 'JE', text: 'Jersey' },
        { id: 'JO', text: 'Jordan' },
        { id: 'KZ', text: 'Kazakhstan' },
        { id: 'KE', text: 'Kenya' },
        { id: 'KI', text: 'Kiribati' },
        { id: 'KR', text: 'Korea' },
        { id: 'KW', text: 'Kuwait' },
        { id: 'KG', text: 'Kyrgyzstan' },
        { id: 'LA', text: 'Lao People\'s Democratic Republic' },
        { id: 'LV', text: 'Latvia' },
        { id: 'LB', text: 'Lebanon' },
        { id: 'LS', text: 'Lesotho' },
        { id: 'LR', text: 'Liberia' },
        { id: 'LY', text: 'Libyan Arab Jamahiriya' },
        { id: 'LI', text: 'Liechtenstein' },
        { id: 'LT', text: 'Lithuania' },
        { id: 'LU', text: 'Luxembourg' },
        { id: 'MO', text: 'Macao' },
        { id: 'MK', text: 'Macedonia' },
        { id: 'MG', text: 'Madagascar' },
        { id: 'MW', text: 'Malawi' },
        { id: 'MY', text: 'Malaysia' },
        { id: 'MV', text: 'Maldives' },
        { id: 'ML', text: 'Mali' },
        { id: 'MT', text: 'Malta' },
        { id: 'MH', text: 'Marshall Islands' },
        { id: 'MQ', text: 'Martinique' },
        { id: 'MR', text: 'Mauritania' },
        { id: 'MU', text: 'Mauritius' },
        { id: 'YT', text: 'Mayotte' },
        { id: 'MX', text: 'Mexico' },
        { id: 'FM', text: 'Micronesia}, Federated States Of' },
        { id: 'MD', text: 'Moldova' },
        { id: 'MC', text: 'Monaco' },
        { id: 'MN', text: 'Mongolia' },
        { id: 'ME', text: 'Montenegro' },
        { id: 'MS', text: 'Montserrat' },
        { id: 'MA', text: 'Morocco' },
        { id: 'MZ', text: 'Mozambique' },
        { id: 'MM', text: 'Myanmar' },
        { id: 'NA', text: 'Namibia' },
        { id: 'NR', text: 'Nauru' },
        { id: 'NP', text: 'Nepal' },
        { id: 'NL', text: 'Netherlands' },
        { id: 'AN', text: 'Netherlands Antilles' },
        { id: 'NC', text: 'New Caledonia' },
        { id: 'NZ', text: 'New Zealand' },
        { id: 'NI', text: 'Nicaragua' },
        { id: 'NE', text: 'Niger' },
        { id: 'NU', text: 'Niue' },
        { id: 'NF', text: 'Norfolk Island' },
        { id: 'MP', text: 'Northern Mariana Islands' },
        { id: 'NO', text: 'Norway' },
        { id: 'OM', text: 'Oman' },
        { id: 'PK', text: 'Pakistan' },
        { id: 'PW', text: 'Palau' },
        { id: 'PS', text: 'Palestinian Territory}, Occupied' },
        { id: 'PA', text: 'Panama' },
        { id: 'PG', text: 'Papua New Guinea' },
        { id: 'PY', text: 'Paraguay' },
        { id: 'PE', text: 'Peru' },
        { id: 'PH', text: 'Philippines' },
        { id: 'PN', text: 'Pitcairn' },
        { id: 'PL', text: 'Poland' },
        { id: 'PT', text: 'Portugal' },
        { id: 'PR', text: 'Puerto Rico' },
        { id: 'QA', text: 'Qatar' },
        { id: 'RE', text: 'Reunion' },
        { id: 'RO', text: 'Romania' },
        { id: 'RU', text: 'Russian Federation' },
        { id: 'RW', text: 'Rwanda' },
        { id: 'BL', text: 'Saint Barthelemy' },
        { id: 'SH', text: 'Saint Helena' },
        { id: 'KN', text: 'Saint Kitts And Nevis' },
        { id: 'LC', text: 'Saint Lucia' },
        { id: 'MF', text: 'Saint Martin' },
        { id: 'PM', text: 'Saint Pierre And Miquelon' },
        { id: 'VC', text: 'Saint Vincent And Grenadines' },
        { id: 'WS', text: 'Samoa' },
        { id: 'SM', text: 'San Marino' },
        { id: 'ST', text: 'Sao Tome And Principe' },
        { id: 'SA', text: 'Saudi Arabia' },
        { id: 'SN', text: 'Senegal' },
        { id: 'RS', text: 'Serbia' },
        { id: 'SC', text: 'Seychelles' },
        { id: 'SL', text: 'Sierra Leone' },
        { id: 'SG', text: 'Singapore' },
        { id: 'SK', text: 'Slovakia' },
        { id: 'SI', text: 'Slovenia' },
        { id: 'SB', text: 'Solomon Islands' },
        { id: 'SO', text: 'Somalia' },
        { id: 'ZA', text: 'South Africa' },
        { id: 'GS', text: 'South Georgia And Sandwich Isl.' },
        { id: 'ES', text: 'Spain' },
        { id: 'LK', text: 'Sri Lanka' },
        { id: 'SD', text: 'Sudan' },
        { id: 'SR', text: 'Suriname' },
        { id: 'SJ', text: 'Svalbard And Jan Mayen' },
        { id: 'SZ', text: 'Swaziland' },
        { id: 'SE', text: 'Sweden' },
        { id: 'CH', text: 'Switzerland' },
        { id: 'SY', text: 'Syrian Arab Republic' },
        { id: 'TW', text: 'Taiwan' },
        { id: 'TJ', text: 'Tajikistan' },
        { id: 'TZ', text: 'Tanzania' },
        { id: 'TH', text: 'Thailand' },
        { id: 'TL', text: 'Timor-Leste' },
        { id: 'TG', text: 'Togo' },
        { id: 'TK', text: 'Tokelau' },
        { id: 'TO', text: 'Tonga' },
        { id: 'TT', text: 'Trinidad And Tobago' },
        { id: 'TN', text: 'Tunisia' },
        { id: 'TR', text: 'Turkey' },
        { id: 'TM', text: 'Turkmenistan' },
        { id: 'TC', text: 'Turks And Caicos Islands' },
        { id: 'TV', text: 'Tuvalu' },
        { id: 'UG', text: 'Uganda' },
        { id: 'UA', text: 'Ukraine' },
        { id: 'AE', text: 'United Arab Emirates' },
        { id: 'GB', text: 'United Kingdom' },
        { id: 'US', text: 'United States' },
        { id: 'UM', text: 'United States Outlying Islands' },
        { id: 'UY', text: 'Uruguay' },
        { id: 'UZ', text: 'Uzbekistan' },
        { id: 'VU', text: 'Vanuatu' },
        { id: 'VE', text: 'Venezuela' },
        { id: 'VN', text: 'Viet Nam' },
        { id: 'VG', text: 'Virgin Islands}, British' },
        { id: 'VI', text: 'Virgin Islands}, U.S.' },
        { id: 'WF', text: 'Wallis And Futuna' },
        { id: 'EH', text: 'Western Sahara' },
        { id: 'YE', text: 'Yemen' },
        { id: 'ZM', text: 'Zambia' },
        { id: 'ZW', text: 'Zimbabwe' }
    ];

    function fnformatCountryDropdown(country) { 

        if (!country.id) { return country.text; }
        var control_country = $(
            '<span class="flag-icon flag-icon-' + country.id.toLowerCase() + ' flag-icon-squared"></span>' +
            '<span class="flag-text">' + country.text + "</span>"
        );
        return control_country;
    };

    //Assuming you have a select element with name country
    // e.g. <select name="name"></select>    "[name='country']"
    $(`${controlID}`).html("");
    $(`${controlID}`).select2({
        placeholder: "Select a country",
        templateResult: fnformatCountryDropdown,
        data: isoCountries
    }); 
}

 
export const LoadCountrySelectDropdown = function () {
    return {
        
        Init: function (controlID) {
            if(controlID == undefined || (controlID||"") == ""){
                $(".load-select-country-data").each(function () {
                    let formControl = "#" + $(this).prop("id");
                    fnCountryDropdownPopulate(formControl);
                });
            }
            else{
                fnCountryDropdownPopulate(controlID);
            }
        }
    };
}();

//>>>>>>>>>>>>>>>>>>>>>>>
export function fxGotoStep(wizard, step) {   gotoStep(wizard, step); }


//var JSON_COUNTRY_STATES = [], JSON_NIGERIA_LGAS = [];
export function loadCountryStateLgasJson() {
    $.get("json/country_states.json?v=adfadsf", {}, function (result) { JSON_COUNTRY_STATES = result || []; });
    $.get("json/nigeria_state_lgas.json?v=erwqe", {}, function (result) { JSON_NIGERIA_LGAS = result || []; });
}

export function fxnCountriesJsonData() { return JSON_COUNTRY_STATES || []; }
export function fxnLgasJsonData() { return JSON_NIGERIA_LGAS || []; }
export function fxnDropdownJsonData(){
    return {
        Title: ["Mr", "Mrs", "Ms", "Miss"],
        Gender: ["Female", "Male"],
        Marital: ["Single", "Married","Divorced", "Separated", "Widow"],
        Relationship: ["Mother", "Father", "Sibling"],
        EmployerType: ["PU", "ST", "PR", "BR", "FR", "EM", "NG", "UN", "MP", "CB"],
        Location: ["Abuja", "Lagos", "Port Harcourt", "Benin", "Kaduna", "Kano"],
        PensionScheme: ["Scheme 1", "Scheme 2", "Scheme 3"],
        GradeLevel: ["Grade 1", "Grade 2", "Grade 3", "Grade 4"],
        GradeStep: ["Step 1", "Step 2", "Step 3", "Step 4"],
        Bank: ["Access", "GTB", "Zenith", "OPay"]
    };       
}

export function fxnLoadCountryStates_bb(controlCountryId, controlStateId, controlLgaId, isSelect2) {
    var countryId = $(controlCountryId).val();
    console.log("core service:", countryId);

    // try { $(controlStateId).select2("destroy"); } catch (ex) { }
    // try { $(controlLgaId).select2("destroy"); } catch (ex) { }
    $(controlLgaId).html("");

    var objCountry = JSON_COUNTRY_STATES.find(x => x.code3 == countryId);
    if (objCountry == null) {
        return;
    }

    var opts = "<option value=''>Select State</option>";
    $.each(objCountry.states, function (indx, opt) {
        opts += `<option value='${opt.code}'>${opt.name}</option>`;
    });

    //try { $(controlStateId).select2("destroy"); } catch (ex) { }
    //try { $(controlLgaId).select2("destroy"); } catch (ex) { }

    $(controlStateId).html(opts);
    //$(controlLgaId).html("");

    if (isSelect2 == "select2") {
        try {
            $(controlStateId).select2({
                placeholder: "Select State",  // tags: "true",
                closeOnSelect: true,
            }).on('select2:open', function (e) {
                $('.select2-dropdown').addClass("uk-animation-slide-top-small");
            });
        } catch (exx) { }

        try {
            $(controlLgaId).select2({
                placeholder: "Select LGA",  // tags: "true",
                closeOnSelect: true,
            }).on('select2:open', function (e) {
                $('.select2-dropdown').addClass("uk-animation-slide-top-small");
            });
        } catch (exx) { }
    }

}

export function fxnLoadStateLGAs(controlStateId, controlLgaId, isSelect2) {

    try { $(controlLgaId).select2("destroy"); } catch (ex) { }
    $(controlLgaId).html("");

    var stateId = $(controlStateId).val();

    var objState = JSON_NIGERIA_LGAS.find(x => x.code == stateId);

    if (objState == null || (objState.lgas || []).length == 0) {
        return;
    }

    var opts = "<option value=''>Select LGA</option>";
    $.each(objState.lgas, function (indx, opt) {
        opts += `<option value='${opt}'>${opt}</option>`;
    });

    $(controlLgaId).html(opts);

    if (isSelect2 == "select2") {
        try {
            $(controlLgaId).select2({
                placeholder: "Select LGA",  // tags: "true",
                closeOnSelect: true,
            }).on('select2:open', function (e) {
                $('.select2-dropdown').addClass("uk-animation-slide-top-small");
            });
        } catch (exx) { }
    }
}

