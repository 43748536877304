import { ref, computed } from 'vue';
import { defineStore } from 'pinia'; 
 
const appJs = require('@/assets/app-service.js');

export interface ICountry{
    code: string;    
    code3: string;
    name: string;
    capital: string;
    region: string;
    subregion: string;
    states: ICountryState[];     
}

export interface ICountryState{
  code: string;    
  name: string;     
}

export const CountryStore = defineStore('StoreCountry', () => {
  
  const model = ref({
    selectedCountry: <ICountry | null>{},
    selectedState: <ICountryState | null>{},
    //dropdown: <IBasicDropdown | null>{},
    //-----------------
    //disableBulkAdd: false,
  });

  const listCountries = ref<ICountry[] | []>();
     
  const Init = () => {
    console.log("country_init");
     if(listCountries.value == undefined ||
        listCountries.value.length == 0) {
          appJs.SpaAjax.Get("json/country_states.json?v=alda",{}, function(data: any) {
              listCountries.value = data;
          }); 
     }
  }
   
  return {
    model,
    listCountries,
    Init
  }

});

    